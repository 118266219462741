/* components/LegendOccupations.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element,
	text
} from "svelte/internal";

import { scaleLinear } from "d3-scale";

import {
	color_nuts2_names_bground,
	color_nuts2_names,
	color_skills,
	color_legend_bground
} from "../shared/colours";

import { _margin, _width, _xScale } from "../stores";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[10] = list[i];
	child_ctx[12] = i;
	return child_ctx;
}

// (37:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let rect;
	let rect_x_value;
	let rect_y_value;
	let rect_width_value;
	let rect_height_value;
	let text_1;
	let t;
	let text_1_x_value;
	let text_1_y_value;
	let g_transform_value;
	let each_value = /*color_skills_domain*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");
			rect = svg_element("rect");
			text_1 = svg_element("text");
			t = text("Occupational skill mixes\n\t\t\t\t");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(rect, "x", rect_x_value = /*$_xScale*/ ctx[4](0));
			attr(rect, "y", rect_y_value = /*yScaleLeg*/ ctx[5](100));
			attr(rect, "width", rect_width_value = /*$_xScale*/ ctx[4](100));
			attr(rect, "height", rect_height_value = /*yScaleLeg*/ ctx[5](0));
			attr(rect, "fill", color_legend_bground);
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
			attr(text_1, "class", "occupation_legend_title svelte-hyi1vl");
			attr(text_1, "dy", "0.3em");
			attr(text_1, "text-anchor", "start");
			attr(text_1, "fill", color_nuts2_names);

			attr(text_1, "x", text_1_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](2)
			: /*$_xScale*/ ctx[4](30));

			attr(text_1, "y", text_1_y_value = /*yScaleLeg*/ ctx[5](90));
			attr(g, "transform", g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);
			append(g, rect);
			append(g, text_1);
			append(text_1, t);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$_xScale*/ 16 && rect_x_value !== (rect_x_value = /*$_xScale*/ ctx[4](0))) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*$_xScale*/ 16 && rect_width_value !== (rect_width_value = /*$_xScale*/ ctx[4](100))) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty & /*isSmall, $_xScale*/ 20 && text_1_x_value !== (text_1_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](2)
			: /*$_xScale*/ ctx[4](30))) {
				attr(text_1, "x", text_1_x_value);
			}

			if (dirty & /*color_nuts2_names, isSmall, $_xScale, yScaleLeg, no_broad_skills, color_skills_domain, color_skills*/ 244) {
				each_value = /*color_skills_domain*/ ctx[6];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$_margin*/ 2 && g_transform_value !== (g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty & /*height*/ 8) {
				attr(svg, "height", /*height*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (65:4) {#each color_skills_domain as d,i}
function create_each_block(ctx) {
	let rect;
	let rect_fill_value;
	let rect_x_value;
	let rect_y_value;
	let rect_width_value;
	let rect_height_value;
	let text_1;
	let t_value = /*d*/ ctx[10] + "";
	let t;
	let text_1_text_anchor_value;
	let text_1_x_value;
	let text_1_y_value;

	return {
		c() {
			rect = svg_element("rect");
			text_1 = svg_element("text");
			t = text(t_value);
			attr(rect, "class", "occupation_legend_rect svelte-hyi1vl");
			attr(rect, "fill", rect_fill_value = color_skills(/*d*/ ctx[10]));

			attr(rect, "x", rect_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](2)
			: /*$_xScale*/ ctx[4](30));

			attr(rect, "y", rect_y_value = /*yScaleLeg*/ ctx[5](80 - 75 / /*no_broad_skills*/ ctx[7] * /*i*/ ctx[12]));

			attr(rect, "width", rect_width_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](10)
			: /*$_xScale*/ ctx[4](50 / /*no_broad_skills*/ ctx[7]));

			attr(rect, "height", rect_height_value = /*yScaleLeg*/ ctx[5](100 - 75 / (/*no_broad_skills*/ ctx[7] + 0.5)));
			attr(text_1, "class", "occupation_legend_text svelte-hyi1vl");
			attr(text_1, "text-anchor", text_1_text_anchor_value = "start");
			attr(text_1, "dy", "0.3em");
			attr(text_1, "fill", color_nuts2_names);

			attr(text_1, "x", text_1_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](14)
			: /*$_xScale*/ ctx[4](38));

			attr(text_1, "y", text_1_y_value = /*yScaleLeg*/ ctx[5](80 - 75 / /*no_broad_skills*/ ctx[7] * (/*i*/ ctx[12] + 0.5)));
		},
		m(target, anchor) {
			insert(target, rect, anchor);
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*isSmall, $_xScale*/ 20 && rect_x_value !== (rect_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](2)
			: /*$_xScale*/ ctx[4](30))) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*isSmall, $_xScale*/ 20 && rect_width_value !== (rect_width_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](10)
			: /*$_xScale*/ ctx[4](50 / /*no_broad_skills*/ ctx[7]))) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty & /*isSmall, $_xScale*/ 20 && text_1_x_value !== (text_1_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[4](14)
			: /*$_xScale*/ ctx[4](38))) {
				attr(text_1, "x", text_1_x_value);
			}
		},
		d(detaching) {
			if (detaching) detach(rect);
			if (detaching) detach(text_1);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "div_background svelte-hyi1vl");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[0] && /*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

const legendsHeight = 220;
const small_width = 850;
const thickStroke = "5px";
const thinStroke = "1px";

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isSmall;
	let opacity;
	let $_width;
	let $_margin;
	let $_xScale;
	component_subscribe($$self, _width, $$value => $$invalidate(8, $_width = $$value));
	component_subscribe($$self, _margin, $$value => $$invalidate(1, $_margin = $$value));
	component_subscribe($$self, _xScale, $$value => $$invalidate(4, $_xScale = $$value));
	const yScaleLeg = scaleLinear().domain([0, 100]).range([legendsHeight, 0]);
	const color_skills_domain = color_skills.domain();
	const no_broad_skills = color_skills_domain.length;

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_width, $_margin*/ 258) {
			/* reactive vars */
			$: $$invalidate(0, width = $_width + $_margin.left + $_margin.right);
		}

		if ($$self.$$.dirty & /*$_margin*/ 2) {
			$: $$invalidate(3, height = legendsHeight + $_margin.top + $_margin.bottom);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: $$invalidate(2, isSmall = width < small_width);
		}

		if ($$self.$$.dirty & /*isSmall*/ 4) {
			$: opacity = isSmall ? 1 : 0;
		}
	};

	return [
		width,
		$_margin,
		isSmall,
		height,
		$_xScale,
		yScaleLeg,
		color_skills_domain,
		no_broad_skills,
		$_width
	];
}

class LegendOccupations extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default LegendOccupations;