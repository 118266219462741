/* components/Title.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	add_resize_listener,
	attr,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	element,
	init,
	insert,
	mount_component,
	noop,
	safe_not_equal,
	transition_in,
	transition_out
} from "svelte/internal";

import ChartTitle from "./ChartTitle.svelte";
import { _heightContainerTitle, _widthContainerTitle } from "../stores";

function create_fragment(ctx) {
	let div;
	let charttitle;
	let div_resize_listener;
	let current;
	charttitle = new ChartTitle({});

	return {
		c() {
			div = element("div");
			create_component(charttitle.$$.fragment);
			attr(div, "class", "container_title svelte-8rxudq");
			add_render_callback(() => /*div_elementresize_handler*/ ctx[2].call(div));
		},
		m(target, anchor) {
			insert(target, div, anchor);
			mount_component(charttitle, div, null);
			div_resize_listener = add_resize_listener(div, /*div_elementresize_handler*/ ctx[2].bind(div));
			current = true;
		},
		p: noop,
		i(local) {
			if (current) return;
			transition_in(charttitle.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(charttitle.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (detaching) detach(div);
			destroy_component(charttitle);
			div_resize_listener();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_heightContainerTitle;
	let $_widthContainerTitle;
	component_subscribe($$self, _heightContainerTitle, $$value => $$invalidate(0, $_heightContainerTitle = $$value));
	component_subscribe($$self, _widthContainerTitle, $$value => $$invalidate(1, $_widthContainerTitle = $$value));

	function div_elementresize_handler() {
		$_heightContainerTitle = this.clientHeight;
		_heightContainerTitle.set($_heightContainerTitle);
		$_widthContainerTitle = this.clientWidth;
		_widthContainerTitle.set($_widthContainerTitle);
	}

	return [$_heightContainerTitle, $_widthContainerTitle, div_elementresize_handler];
}

class Title extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Title;