/* components/ChartTitle.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	svg_element,
	text
} from "svelte/internal";

import data from "../../data/data.json";

import {
	color_skills,
	color_stroke_treemap,
	color_title_text,
	color_title_text_bground
} from "../shared/colours";

import { titleHeight, yScale_t } from "../shared/geometry";
import { _widthContainerTitle, _xScaleT } from "../stores";
import { treemap, hierarchy, treemapSquarify } from "d3-hierarchy";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[9] = list[i];
	child_ctx[11] = i;
	return child_ctx;
}

// (49:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let each0_anchor;
	let if_block_anchor;
	let each1_anchor;
	let text0;
	let t0;
	let text0_y_value;
	let rect;
	let rect_height_value;
	let rect_x_value;
	let rect_y_value;
	let rect_opacity_value;
	let text1;
	let t1;
	let text1_x_value;
	let text1_y_value;
	let text1_opacity_value;
	let each_value_2 = /*root*/ ctx[7].leaves();
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	function select_block_type(ctx, dirty) {
		if (/*isSmall*/ ctx[1]) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, -1);
	let if_block = current_block_type(ctx);
	let each_value_1 = /*title_text*/ ctx[5];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*subtitle_text*/ ctx[6];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			each0_anchor = empty();
			if_block.c();
			if_block_anchor = empty();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each1_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			text0 = svg_element("text");
			t0 = text("Cath Sleeman\n\t\t\t\t");
			rect = svg_element("rect");
			text1 = svg_element("text");
			t1 = text("A treemap showing the demand for skill groups");
			attr(text0, "class", "author_text svelte-nun81k");
			attr(text0, "fill", color_title_text);
			attr(text0, "x", "20");
			attr(text0, "y", text0_y_value = yScale_t(6.5));
			attr(rect, "class", "explainer_bground_rect");
			attr(rect, "width", "320");
			attr(rect, "height", rect_height_value = yScale_t(94));
			attr(rect, "x", rect_x_value = /*$_xScaleT*/ ctx[2](100) - 320);
			attr(rect, "y", rect_y_value = yScale_t(6));
			attr(rect, "fill", color_title_text_bground);
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
			attr(rect, "opacity", rect_opacity_value = /*isSmall*/ ctx[1] ? 0 : 0.9);
			attr(text1, "class", "explainer_text svelte-nun81k");
			attr(text1, "fill", color_title_text);
			attr(text1, "x", text1_x_value = /*$_xScaleT*/ ctx[2](99.5));
			attr(text1, "y", text1_y_value = yScale_t(1.5));
			attr(text1, "opacity", text1_opacity_value = /*isSmall*/ ctx[1] ? 0 : 1);
			attr(g, "transform", "translate(0,0)");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(g, null);
			}

			append(g, each0_anchor);
			if_block.m(g, null);
			append(g, if_block_anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, each1_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}

			append(g, text0);
			append(text0, t0);
			append(g, rect);
			append(g, text1);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*root, color_skills, color_stroke_treemap, thinStroke*/ 144) {
				each_value_2 = /*root*/ ctx[7].leaves();
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(g, each0_anchor);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (current_block_type === (current_block_type = select_block_type(ctx, dirty)) && if_block) {
				if_block.p(ctx, dirty);
			} else {
				if_block.d(1);
				if_block = current_block_type(ctx);

				if (if_block) {
					if_block.c();
					if_block.m(g, if_block_anchor);
				}
			}

			if (dirty & /*color_title_text, isSmall, yScale_t, title_text*/ 34) {
				each_value_1 = /*title_text*/ ctx[5];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, each1_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*color_title_text, isSmall, yScale_t, subtitle_text*/ 66) {
				each_value = /*subtitle_text*/ ctx[6];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, text0);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$_xScaleT*/ 4 && rect_x_value !== (rect_x_value = /*$_xScaleT*/ ctx[2](100) - 320)) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*isSmall*/ 2 && rect_opacity_value !== (rect_opacity_value = /*isSmall*/ ctx[1] ? 0 : 0.9)) {
				attr(rect, "opacity", rect_opacity_value);
			}

			if (dirty & /*$_xScaleT*/ 4 && text1_x_value !== (text1_x_value = /*$_xScaleT*/ ctx[2](99.5))) {
				attr(text1, "x", text1_x_value);
			}

			if (dirty & /*isSmall*/ 2 && text1_opacity_value !== (text1_opacity_value = /*isSmall*/ ctx[1] ? 0 : 1)) {
				attr(text1, "opacity", text1_opacity_value);
			}

			if (dirty & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty & /*height*/ 8) {
				attr(svg, "height", /*height*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks_2, detaching);
			if_block.d();
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (54:4) {#each root.leaves() as d,i}
function create_each_block_2(ctx) {
	let g;
	let rect;
	let rect_width_value;
	let rect_height_value;
	let rect_fill_value;
	let g_transform_value;

	return {
		c() {
			g = svg_element("g");
			rect = svg_element("rect");
			attr(rect, "class", "treemap_rect");
			attr(rect, "width", rect_width_value = /*d*/ ctx[9].x1 - /*d*/ ctx[9].x0);
			attr(rect, "height", rect_height_value = /*d*/ ctx[9].y1 - /*d*/ ctx[9].y0);
			attr(rect, "fill", rect_fill_value = color_skills(/*d*/ ctx[9].parent.data.name));
			attr(rect, "stroke", color_stroke_treemap);
			attr(rect, "stroke-width", /*thinStroke*/ ctx[4]);
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
			attr(g, "class", "treemap_group");
			attr(g, "transform", g_transform_value = "translate(" + /*d*/ ctx[9].x0 + "," + /*d*/ ctx[9].y0 + ")");
		},
		m(target, anchor) {
			insert(target, g, anchor);
			append(g, rect);
		},
		p(ctx, dirty) {
			if (dirty & /*root*/ 128 && rect_width_value !== (rect_width_value = /*d*/ ctx[9].x1 - /*d*/ ctx[9].x0)) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty & /*root*/ 128 && rect_height_value !== (rect_height_value = /*d*/ ctx[9].y1 - /*d*/ ctx[9].y0)) {
				attr(rect, "height", rect_height_value);
			}

			if (dirty & /*root*/ 128 && rect_fill_value !== (rect_fill_value = color_skills(/*d*/ ctx[9].parent.data.name))) {
				attr(rect, "fill", rect_fill_value);
			}

			if (dirty & /*thinStroke*/ 16) {
				attr(rect, "stroke-width", /*thinStroke*/ ctx[4]);
			}

			if (dirty & /*root*/ 128 && g_transform_value !== (g_transform_value = "translate(" + /*d*/ ctx[9].x0 + "," + /*d*/ ctx[9].y0 + ")")) {
				attr(g, "transform", g_transform_value);
			}
		},
		d(detaching) {
			if (detaching) detach(g);
		}
	};
}

// (85:4) {:else}
function create_else_block(ctx) {
	let rect0;
	let rect0_height_value;
	let rect0_y_value;
	let rect1;
	let rect1_height_value;
	let rect1_y_value;
	let rect2;
	let rect2_height_value;
	let rect2_y_value;

	return {
		c() {
			rect0 = svg_element("rect");
			rect1 = svg_element("rect");
			rect2 = svg_element("rect");
			attr(rect0, "class", "title_bground_rect svelte-nun81k");
			attr(rect0, "width", "960");
			attr(rect0, "height", rect0_height_value = yScale_t(78));
			attr(rect0, "x", "10");
			attr(rect0, "y", rect0_y_value = yScale_t(87));
			attr(rect0, "fill", color_title_text_bground);
			attr(rect0, "rx", "4px");
			attr(rect0, "ry", "4px");
			attr(rect1, "class", "subtitle_bground_rect svelte-nun81k");
			attr(rect1, "width", "680");
			attr(rect1, "height", rect1_height_value = yScale_t(88));
			attr(rect1, "x", "10");
			attr(rect1, "y", rect1_y_value = yScale_t(44));
			attr(rect1, "fill", color_title_text_bground);
			attr(rect1, "rx", "4px");
			attr(rect1, "ry", "4px");
			attr(rect2, "class", "author_bground_rect svelte-nun81k");
			attr(rect2, "width", "120");
			attr(rect2, "height", rect2_height_value = yScale_t(94));
			attr(rect2, "x", "10");
			attr(rect2, "y", rect2_y_value = yScale_t(11));
			attr(rect2, "fill", color_title_text_bground);
			attr(rect2, "rx", "4px");
			attr(rect2, "ry", "4px");
		},
		m(target, anchor) {
			insert(target, rect0, anchor);
			insert(target, rect1, anchor);
			insert(target, rect2, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(rect0);
			if (detaching) detach(rect1);
			if (detaching) detach(rect2);
		}
	};
}

// (73:4) {#if isSmall}
function create_if_block_1(ctx) {
	let rect;
	let rect_height_value;
	let rect_y_value;

	return {
		c() {
			rect = svg_element("rect");
			attr(rect, "class", "title_bground_rect svelte-nun81k");
			attr(rect, "width", "285");
			attr(rect, "height", rect_height_value = yScale_t(12));
			attr(rect, "x", "10");
			attr(rect, "y", rect_y_value = yScale_t(92.5));
			attr(rect, "fill", color_title_text_bground);
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
		},
		m(target, anchor) {
			insert(target, rect, anchor);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(rect);
		}
	};
}

// (122:4) {#each title_text as d,i}
function create_each_block_1(ctx) {
	let text_1;
	let t_value = /*d*/ ctx[9] + "";
	let t;
	let text_1_y_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			attr(text_1, "class", "title_text svelte-nun81k");
			attr(text_1, "fill", color_title_text);
			attr(text_1, "x", "20");

			attr(text_1, "y", text_1_y_value = /*isSmall*/ ctx[1]
			? yScale_t(80 - 14 * /*i*/ ctx[11])
			: yScale_t(70));
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*title_text*/ 32 && t_value !== (t_value = /*d*/ ctx[9] + "")) set_data(t, t_value);

			if (dirty & /*isSmall*/ 2 && text_1_y_value !== (text_1_y_value = /*isSmall*/ ctx[1]
			? yScale_t(80 - 14 * /*i*/ ctx[11])
			: yScale_t(70))) {
				attr(text_1, "y", text_1_y_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
		}
	};
}

// (134:4) {#each subtitle_text as d,i}
function create_each_block(ctx) {
	let text_1;
	let t_value = /*d*/ ctx[9] + "";
	let t;
	let text_1_y_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			attr(text_1, "class", "subtitle_text svelte-nun81k");
			attr(text_1, "fill", color_title_text);
			attr(text_1, "x", "20");

			attr(text_1, "y", text_1_y_value = /*isSmall*/ ctx[1]
			? yScale_t(39 - 7 * /*i*/ ctx[11])
			: yScale_t(35));
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*subtitle_text*/ 64 && t_value !== (t_value = /*d*/ ctx[9] + "")) set_data(t, t_value);

			if (dirty & /*isSmall*/ 2 && text_1_y_value !== (text_1_y_value = /*isSmall*/ ctx[1]
			? yScale_t(39 - 7 * /*i*/ ctx[11])
			: yScale_t(35))) {
				attr(text_1, "y", text_1_y_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[0] && /*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

const strokeWidth = "5px";
const smallWidth = 980;

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isSmall;
	let thinStroke;
	let title_text;
	let subtitle_text;
	let root;
	let $_widthContainerTitle;
	let $_xScaleT;
	component_subscribe($$self, _widthContainerTitle, $$value => $$invalidate(8, $_widthContainerTitle = $$value));
	component_subscribe($$self, _xScaleT, $$value => $$invalidate(2, $_xScaleT = $$value));

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_widthContainerTitle*/ 256) {
			/* reactive vars */
			$: $$invalidate(0, width = $_widthContainerTitle);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: $$invalidate(1, isSmall = width < smallWidth);
		}

		if ($$self.$$.dirty & /*$_xScaleT*/ 4) {
			$: $$invalidate(4, thinStroke = $_xScaleT(0.2));
		}

		if ($$self.$$.dirty & /*isSmall*/ 2) {
			$: $$invalidate(5, title_text = isSmall
			? ["The Open", "Jobs", "Observatory"]
			: ["The Open Jobs Observatory"]);
		}

		if ($$self.$$.dirty & /*isSmall*/ 2) {
			$: $$invalidate(6, subtitle_text = isSmall
			? ["Providing new", "insights on UK", "skill demands"]
			: ["Providing new insights on UK skill demands"]);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: $$invalidate(7, root = treemap().tile(treemapSquarify).size([width, titleHeight]).padding(1).round(true)(hierarchy(data.skills_taxonomy).sum(d => d.value).sort((a, b) => b.value - a.value)));
		}
	};

	$: $$invalidate(3, height = titleHeight);

	return [
		width,
		isSmall,
		$_xScaleT,
		height,
		thinStroke,
		title_text,
		subtitle_text,
		root,
		$_widthContainerTitle
	];
}

class ChartTitle extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ChartTitle;