/* components/LegendSalaries.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element,
	text
} from "svelte/internal";

import { scaleLinear } from "d3-scale";

import {
	color_salary_names,
	color_skills,
	color_legend_bground
} from "../shared/colours";

import { _margin, _width, _xScale } from "../stores";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	child_ctx[16] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[14] = list[i];
	child_ctx[16] = i;
	return child_ctx;
}

// (47:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let rect0;
	let rect0_x_value;
	let rect0_y_value;
	let rect0_width_value;
	let rect0_height_value;
	let rect1;
	let rect1_fill_value;
	let rect1_stroke_value;
	let rect1_x_value;
	let rect1_y_value;
	let rect1_width_value;
	let rect1_height_value;
	let rect2;
	let rect2_fill_value;
	let rect2_stroke_value;
	let rect2_x_value;
	let rect2_y_value;
	let rect2_width_value;
	let rect2_height_value;
	let g_transform_value;
	let each_value_1 = /*titleLines*/ ctx[10];
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*y_positions*/ ctx[7];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");
			rect0 = svg_element("rect");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			rect1 = svg_element("rect");
			rect2 = svg_element("rect");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(rect0, "x", rect0_x_value = /*$_xScale*/ ctx[2](0));
			attr(rect0, "y", rect0_y_value = /*yScaleLeg*/ ctx[6](100));
			attr(rect0, "width", rect0_width_value = /*$_xScale*/ ctx[2](100));
			attr(rect0, "height", rect0_height_value = /*yScaleLeg*/ ctx[6](0));
			attr(rect0, "fill", color_legend_bground);
			attr(rect0, "rx", "4px");
			attr(rect0, "ry", "4px");
			attr(rect1, "class", "salary_legend_long_rect svelte-1h6atwr");
			attr(rect1, "fill", rect1_fill_value = /*color_skills_range*/ ctx[9][3]);
			attr(rect1, "stroke", rect1_stroke_value = /*color_skills_range*/ ctx[9][3]);
			attr(rect1, "x", rect1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6));
			attr(rect1, "y", rect1_y_value = /*yScaleLeg*/ ctx[6](/*y_positions*/ ctx[7][0]));
			attr(rect1, "width", rect1_width_value = /*$_xScale*/ ctx[2](5));
			attr(rect1, "height", rect1_height_value = /*yScaleLeg*/ ctx[6](100 - (/*y_positions*/ ctx[7][0] - /*y_positions*/ ctx[7][3])));
			attr(rect1, "stroke-width", /*thinStroke*/ ctx[5]);
			attr(rect1, "opacity", "0.4");
			attr(rect2, "class", "salary_legend_short_rect svelte-1h6atwr");
			attr(rect2, "fill", rect2_fill_value = /*color_skills_range*/ ctx[9][3]);
			attr(rect2, "stroke", rect2_stroke_value = /*color_skills_range*/ ctx[9][3]);
			attr(rect2, "x", rect2_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6));
			attr(rect2, "y", rect2_y_value = /*yScaleLeg*/ ctx[6](/*y_positions*/ ctx[7][1]));
			attr(rect2, "width", rect2_width_value = /*$_xScale*/ ctx[2](5));
			attr(rect2, "height", rect2_height_value = /*yScaleLeg*/ ctx[6](100 - (/*y_positions*/ ctx[7][1] - /*y_positions*/ ctx[7][2])));
			attr(rect2, "stroke-width", /*thinStroke*/ ctx[5]);
			attr(g, "transform", g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);
			append(g, rect0);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, rect1);
			append(g, rect2);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*$_xScale*/ 4 && rect0_x_value !== (rect0_x_value = /*$_xScale*/ ctx[2](0))) {
				attr(rect0, "x", rect0_x_value);
			}

			if (dirty & /*$_xScale*/ 4 && rect0_width_value !== (rect0_width_value = /*$_xScale*/ ctx[2](100))) {
				attr(rect0, "width", rect0_width_value);
			}

			if (dirty & /*color_salary_names, $_xScale, x_text_start, yScaleLeg, y_positions, titleLines*/ 1236) {
				each_value_1 = /*titleLines*/ ctx[10];
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, rect1);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*$_xScale, x_text_start*/ 20 && rect1_x_value !== (rect1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6))) {
				attr(rect1, "x", rect1_x_value);
			}

			if (dirty & /*$_xScale*/ 4 && rect1_width_value !== (rect1_width_value = /*$_xScale*/ ctx[2](5))) {
				attr(rect1, "width", rect1_width_value);
			}

			if (dirty & /*thinStroke*/ 32) {
				attr(rect1, "stroke-width", /*thinStroke*/ ctx[5]);
			}

			if (dirty & /*$_xScale, x_text_start*/ 20 && rect2_x_value !== (rect2_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6))) {
				attr(rect2, "x", rect2_x_value);
			}

			if (dirty & /*$_xScale*/ 4 && rect2_width_value !== (rect2_width_value = /*$_xScale*/ ctx[2](5))) {
				attr(rect2, "width", rect2_width_value);
			}

			if (dirty & /*thinStroke*/ 32) {
				attr(rect2, "stroke-width", /*thinStroke*/ ctx[5]);
			}

			if (dirty & /*color_salary_names, $_xScale, x_text_start, yScaleLeg, y_positions, legend_text, thinStroke*/ 500) {
				each_value = /*y_positions*/ ctx[7];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$_margin*/ 2 && g_transform_value !== (g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty & /*height*/ 8) {
				attr(svg, "height", /*height*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (63:4) {#each titleLines as d, i}
function create_each_block_1(ctx) {
	let text_1;
	let t_value = /*d*/ ctx[14] + "";
	let t;
	let text_1_x_value;
	let text_1_y_value;
	let text_1_dy_value;
	let text_1_font_size_value;
	let text_1_font_weight_value;
	let text_1_text_transform_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			attr(text_1, "class", "salary_legend_title");
			attr(text_1, "fill", color_salary_names);
			attr(text_1, "x", text_1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6));
			attr(text_1, "y", text_1_y_value = /*yScaleLeg*/ ctx[6](/*y_positions*/ ctx[7][0] + 27));

			attr(text_1, "dy", text_1_dy_value = /*i*/ ctx[16] == 2
			? /*i*/ ctx[16] * 1.7 + "em"
			: /*i*/ ctx[16] * 1.3 + "em");

			attr(text_1, "font-size", text_1_font_size_value = /*i*/ ctx[16] == 2 ? "14px" : "16px");
			attr(text_1, "font-weight", text_1_font_weight_value = /*i*/ ctx[16] == 2 ? "normal" : "bold");
			attr(text_1, "text-transform", text_1_text_transform_value = "" + ((/*i*/ ctx[16] == 2 ? "uppercase" : "none") + ";"));
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*$_xScale, x_text_start*/ 20 && text_1_x_value !== (text_1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 6))) {
				attr(text_1, "x", text_1_x_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
		}
	};
}

// (103:4) {#each y_positions as d,i}
function create_each_block(ctx) {
	let path;
	let path_d_value;
	let text_1;
	let t_value = /*legend_text*/ ctx[8][/*i*/ ctx[16]] + "";
	let t;
	let text_1_x_value;
	let text_1_y_value;
	let text_1_dy_value;

	return {
		c() {
			path = svg_element("path");
			text_1 = svg_element("text");
			t = text(t_value);
			attr(path, "class", "salary_legend_lines svelte-1h6atwr");
			attr(path, "stroke", color_salary_names);
			attr(path, "stroke-width", /*thinStroke*/ ctx[5]);
			attr(path, "d", path_d_value = "M " + /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 1) + "," + /*yScaleLeg*/ ctx[6](/*d*/ ctx[14]) + " L\n\t\t\t\t\t\t" + /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] + 2.5) + "," + /*yScaleLeg*/ ctx[6](/*d*/ ctx[14]));
			attr(text_1, "class", "salary_legend_text svelte-1h6atwr");
			attr(text_1, "fill", color_salary_names);
			attr(text_1, "x", text_1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] + 3));
			attr(text_1, "y", text_1_y_value = /*yScaleLeg*/ ctx[6](/*d*/ ctx[14]));

			attr(text_1, "dy", text_1_dy_value = /*i*/ ctx[16] == 0
			? "0.6em"
			: /*i*/ ctx[16] == 3 ? "0em" : "0.3em");
		},
		m(target, anchor) {
			insert(target, path, anchor);
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*thinStroke*/ 32) {
				attr(path, "stroke-width", /*thinStroke*/ ctx[5]);
			}

			if (dirty & /*$_xScale, x_text_start*/ 20 && path_d_value !== (path_d_value = "M " + /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] - 1) + "," + /*yScaleLeg*/ ctx[6](/*d*/ ctx[14]) + " L\n\t\t\t\t\t\t" + /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] + 2.5) + "," + /*yScaleLeg*/ ctx[6](/*d*/ ctx[14]))) {
				attr(path, "d", path_d_value);
			}

			if (dirty & /*$_xScale, x_text_start*/ 20 && text_1_x_value !== (text_1_x_value = /*$_xScale*/ ctx[2](/*x_text_start*/ ctx[4] + 3))) {
				attr(text_1, "x", text_1_x_value);
			}
		},
		d(detaching) {
			if (detaching) detach(path);
			if (detaching) detach(text_1);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "div_background svelte-1h6atwr");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[0] && /*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

const legendsHeight = 220;
const small_width = 500;

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isSmall;
	let x_text_start;
	let thinStroke;
	let $_width;
	let $_margin;
	let $_xScale;
	component_subscribe($$self, _width, $$value => $$invalidate(11, $_width = $$value));
	component_subscribe($$self, _margin, $$value => $$invalidate(1, $_margin = $$value));
	component_subscribe($$self, _xScale, $$value => $$invalidate(2, $_xScale = $$value));
	const yScaleLeg = scaleLinear().domain([0, 100]).range([legendsHeight, 0]);
	const y_positions = [59, 48, 13, 3];

	const legend_text = [
		"Upper quartile of MAX salaries",
		"Median of MAX salaries",
		"Median of MIN salaries",
		"Lower quartile of MIN salaries"
	];

	const color_skills_range = color_skills.range();
	const no_broad_skills = color_skills_range.length;

	const titleLines = [
		"Salary ranges in adverts",
		"that mention the skill group",
		"(MIN offer - MAX offer)"
	];

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_width, $_margin*/ 2050) {
			/* reactive vars */
			$: $$invalidate(0, width = $_width + $_margin.left + $_margin.right);
		}

		if ($$self.$$.dirty & /*$_margin*/ 2) {
			$: $$invalidate(3, height = legendsHeight + $_margin.top + $_margin.bottom);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: $$invalidate(12, isSmall = width < small_width);
		}

		if ($$self.$$.dirty & /*isSmall*/ 4096) {
			$: $$invalidate(4, x_text_start = isSmall ? 10 : 35);
		}

		if ($$self.$$.dirty & /*$_xScale*/ 4) {
			$: $$invalidate(5, thinStroke = $_xScale(0.1));
		}
	};

	return [
		width,
		$_margin,
		$_xScale,
		height,
		x_text_start,
		thinStroke,
		yScaleLeg,
		y_positions,
		legend_text,
		color_skills_range,
		titleLines,
		$_width,
		isSmall
	];
}

class LegendSalaries extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default LegendSalaries;