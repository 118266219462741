/* components/Nesta/Header.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal
} from "svelte/internal";

import nestaLogo from "../../../assets/img/Nesta_Horizontal_Logo_RGB_nobubbles.png";

function create_fragment(ctx) {
	let div1;
	let div0;
	let a;
	let img;
	let img_src_value;

	return {
		c() {
			div1 = element("div");
			div0 = element("div");
			a = element("a");
			img = element("img");
			attr(img, "alt", "Nesta");
			if (img.src !== (img_src_value = nestaLogo)) attr(img, "src", img_src_value);
			attr(img, "class", "svelte-hokfj3");
			attr(a, "href", "https://www.nesta.org.uk/");
			attr(div0, "class", "logo svelte-hokfj3");
			attr(div1, "class", "Header svelte-hokfj3");
		},
		m(target, anchor) {
			insert(target, div1, anchor);
			append(div1, div0);
			append(div0, a);
			append(a, img);
		},
		p: noop,
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div1);
		}
	};
}

class Header extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, null, create_fragment, safe_not_equal, {});
	}
}

export default Header;