/* components/App.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	add_render_callback,
	check_outros,
	component_subscribe,
	create_component,
	destroy_component,
	detach,
	group_outros,
	init,
	insert,
	listen,
	mount_component,
	safe_not_equal,
	space,
	transition_in,
	transition_out
} from "svelte/internal";

import { GoogleAnalytics } from "@beyonk/svelte-google-analytics";
import { onMount } from "svelte";
import Content from "./Content.svelte";
import Footer from "./Nesta/Footer.svelte";
import Header from "./Nesta/Header.svelte";
import Title from "./Title.svelte";
import { isRelease } from "../shared/env";
import { _innerWidth } from "../stores";

function create_if_block(ctx) {
	let title;
	let t0;
	let content;
	let t1;
	let footer;
	let current;
	title = new Title({});
	content = new Content({});
	footer = new Footer({});

	return {
		c() {
			create_component(title.$$.fragment);
			t0 = space();
			create_component(content.$$.fragment);
			t1 = space();
			create_component(footer.$$.fragment);
		},
		m(target, anchor) {
			mount_component(title, target, anchor);
			insert(target, t0, anchor);
			mount_component(content, target, anchor);
			insert(target, t1, anchor);
			mount_component(footer, target, anchor);
			current = true;
		},
		i(local) {
			if (current) return;
			transition_in(title.$$.fragment, local);
			transition_in(content.$$.fragment, local);
			transition_in(footer.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(title.$$.fragment, local);
			transition_out(content.$$.fragment, local);
			transition_out(footer.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			destroy_component(title, detaching);
			if (detaching) detach(t0);
			destroy_component(content, detaching);
			if (detaching) detach(t1);
			destroy_component(footer, detaching);
		}
	};
}

function create_fragment(ctx) {
	let t;
	let googleanalytics;
	let current;
	let mounted;
	let dispose;
	add_render_callback(/*onwindowresize*/ ctx[2]);
	let if_block = /*isReady*/ ctx[0] && create_if_block(ctx);

	googleanalytics = new GoogleAnalytics({
			props: {
				enabled: isRelease,
				properties: ["UA-1360665-16"]
			}
		});

	return {
		c() {
			if (if_block) if_block.c();
			t = space();
			create_component(googleanalytics.$$.fragment);
		},
		m(target, anchor) {
			if (if_block) if_block.m(target, anchor);
			insert(target, t, anchor);
			mount_component(googleanalytics, target, anchor);
			current = true;

			if (!mounted) {
				dispose = listen(window, "resize", /*onwindowresize*/ ctx[2]);
				mounted = true;
			}
		},
		p(ctx, [dirty]) {
			if (/*isReady*/ ctx[0]) {
				if (if_block) {
					if (dirty & /*isReady*/ 1) {
						transition_in(if_block, 1);
					}
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					transition_in(if_block, 1);
					if_block.m(t.parentNode, t);
				}
			} else if (if_block) {
				group_outros();

				transition_out(if_block, 1, 1, () => {
					if_block = null;
				});

				check_outros();
			}
		},
		i(local) {
			if (current) return;
			transition_in(if_block);
			transition_in(googleanalytics.$$.fragment, local);
			current = true;
		},
		o(local) {
			transition_out(if_block);
			transition_out(googleanalytics.$$.fragment, local);
			current = false;
		},
		d(detaching) {
			if (if_block) if_block.d(detaching);
			if (detaching) detach(t);
			destroy_component(googleanalytics, detaching);
			mounted = false;
			dispose();
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let $_innerWidth;
	component_subscribe($$self, _innerWidth, $$value => $$invalidate(1, $_innerWidth = $$value));
	let isReady = false;

	onMount(async () => {
		// Populate the DOM after mounting to
		// bind resize event handlers correctly.
		$$invalidate(0, isReady = true);
	});

	function onwindowresize() {
		_innerWidth.set($_innerWidth = window.innerWidth)
	}

	return [isReady, $_innerWidth, onwindowresize];
}

class App extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default App;