/* components/ChartSalaries.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	svg_element,
	text,
	toggle_class,
	xlink_attr
} from "svelte/internal";

import data from "../../data/data.json";

import {
	color_skills,
	color_salary_rings,
	color_salary_names_bground,
	color_salary_names
} from "../shared/colours";

import { _margin, _width, _xScaleS, _bodyWidthM } from "../stores";
import { scaleLinear } from "d3-scale";
import { writable } from "svelte/store";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	child_ctx[32] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	child_ctx[32] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	child_ctx[32] = i;
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	child_ctx[32] = i;
	return child_ctx;
}

function get_each_context_4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[30] = list[i];
	return child_ctx;
}

// (158:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let each0_anchor;
	let each1_anchor;
	let each2_anchor;
	let each3_anchor;
	let g_transform_value;
	let each_value_4 = /*salary_rings*/ ctx[11];
	let each_blocks_4 = [];

	for (let i = 0; i < each_value_4.length; i += 1) {
		each_blocks_4[i] = create_each_block_4(get_each_context_4(ctx, each_value_4, i));
	}

	let each_value_3 = /*salary_labels*/ ctx[12];
	let each_blocks_3 = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks_3[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	let each_value_2 = data.salaries;
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = data.salaries;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*salary_rings*/ ctx[11];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");

			for (let i = 0; i < each_blocks_4.length; i += 1) {
				each_blocks_4[i].c();
			}

			each0_anchor = empty();

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				each_blocks_3[i].c();
			}

			each1_anchor = empty();

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			each2_anchor = empty();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each3_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(g, "transform", g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[4]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);

			for (let i = 0; i < each_blocks_4.length; i += 1) {
				each_blocks_4[i].m(g, null);
			}

			append(g, each0_anchor);

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				each_blocks_3[i].m(g, null);
			}

			append(g, each1_anchor);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(g, null);
			}

			append(g, each2_anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, each3_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_xScaleS, yScale_s, salary_rings*/ 2060) {
				each_value_4 = /*salary_rings*/ ctx[11];
				let i;

				for (i = 0; i < each_value_4.length; i += 1) {
					const child_ctx = get_each_context_4(ctx, each_value_4, i);

					if (each_blocks_4[i]) {
						each_blocks_4[i].p(child_ctx, dirty);
					} else {
						each_blocks_4[i] = create_each_block_4(child_ctx);
						each_blocks_4[i].c();
						each_blocks_4[i].m(g, each0_anchor);
					}
				}

				for (; i < each_blocks_4.length; i += 1) {
					each_blocks_4[i].d(1);
				}

				each_blocks_4.length = each_value_4.length;
			}

			if (dirty[0] & /*isVerySmall, salary_labels, makeArc, salary_labels_start_angle, salary_labels_radius*/ 12512) {
				each_value_3 = /*salary_labels*/ ctx[12];
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks_3[i]) {
						each_blocks_3[i].p(child_ctx, dirty);
					} else {
						each_blocks_3[i] = create_each_block_3(child_ctx);
						each_blocks_3[i].c();
						each_blocks_3[i].m(g, each1_anchor);
					}
				}

				for (; i < each_blocks_3.length; i += 1) {
					each_blocks_3[i].d(1);
				}

				each_blocks_3.length = each_value_3.length;
			}

			if (dirty[0] & /*yScale_s, lowest_salary_shown, $_xScaleS, rotate_adj, no_salaries, isVerySmall, showTooltip, onMouseout*/ 214572) {
				each_value_2 = data.salaries;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(g, each2_anchor);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (dirty[0] & /*$_xScaleS, yScale_s, rotate_adj, no_salaries, width*/ 1549) {
				each_value_1 = data.salaries;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, each3_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty[0] & /*$_xScaleS, yScale_s, salary_rings, isVerySmall*/ 2092) {
				each_value = /*salary_rings*/ ctx[11];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty[0] & /*$_margin*/ 2 && g_transform_value !== (g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty[0] & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty[0] & /*height*/ 16) {
				attr(svg, "height", /*height*/ ctx[4]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks_4, detaching);
			destroy_each(each_blocks_3, detaching);
			destroy_each(each_blocks_2, detaching);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (162:4) {#each salary_rings as d}
function create_each_block_4(ctx) {
	let circle;
	let circle_cx_value;
	let circle_cy_value;
	let circle_r_value;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "class", "salary_rings");
			attr(circle, "fill", "transparent");
			attr(circle, "stroke", color_salary_rings);
			attr(circle, "stroke-dasharray", "5,5");
			attr(circle, "cx", circle_cx_value = /*$_xScaleS*/ ctx[3](50));
			attr(circle, "cy", circle_cy_value = /*$_xScaleS*/ ctx[3](50));
			attr(circle, "r", circle_r_value = /*yScale_s*/ ctx[2](/*d*/ ctx[30]));
			attr(circle, "stroke-width", thinStroke);
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_xScaleS*/ 8 && circle_cx_value !== (circle_cx_value = /*$_xScaleS*/ ctx[3](50))) {
				attr(circle, "cx", circle_cx_value);
			}

			if (dirty[0] & /*$_xScaleS*/ 8 && circle_cy_value !== (circle_cy_value = /*$_xScaleS*/ ctx[3](50))) {
				attr(circle, "cy", circle_cy_value);
			}

			if (dirty[0] & /*yScale_s*/ 4 && circle_r_value !== (circle_r_value = /*yScale_s*/ ctx[2](/*d*/ ctx[30]))) {
				attr(circle, "r", circle_r_value);
			}
		},
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (177:4) {#each salary_labels as d,i}
function create_each_block_3(ctx) {
	let path;
	let path_id_value;
	let path_d_value;
	let g;
	let text_1;
	let textPath;
	let t_value = /*d*/ ctx[30] + "";
	let t;
	let textPath_xlink_href_value;
	let textPath_font_size_value;

	return {
		c() {
			path = svg_element("path");
			g = svg_element("g");
			text_1 = svg_element("text");
			textPath = svg_element("textPath");
			t = text(t_value);
			attr(path, "class", "salary_labels_arc svelte-9sx8j8");
			attr(path, "id", path_id_value = "salary_arc" + /*i*/ ctx[32]);
			attr(path, "d", path_d_value = /*makeArc*/ ctx[7](/*salary_labels_start_angle*/ ctx[13][/*i*/ ctx[32]], /*salary_labels_radius*/ ctx[6][/*i*/ ctx[32]]));
			attr(path, "fill", "transparent");
			attr(textPath, "class", "skills_labels_text svelte-9sx8j8");
			attr(textPath, "fill", color_salary_rings);
			attr(textPath, "startOffset", "50%");
			xlink_attr(textPath, "xlink:href", textPath_xlink_href_value = "#salary_arc" + /*i*/ ctx[32]);
			attr(textPath, "font-size", textPath_font_size_value = /*isVerySmall*/ ctx[5] ? "12px" : "15px");
			attr(g, "class", "salary_labels_arc_paths svelte-9sx8j8");
		},
		m(target, anchor) {
			insert(target, path, anchor);
			insert(target, g, anchor);
			append(g, text_1);
			append(text_1, textPath);
			append(textPath, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*makeArc, salary_labels_radius*/ 192 && path_d_value !== (path_d_value = /*makeArc*/ ctx[7](/*salary_labels_start_angle*/ ctx[13][/*i*/ ctx[32]], /*salary_labels_radius*/ ctx[6][/*i*/ ctx[32]]))) {
				attr(path, "d", path_d_value);
			}

			if (dirty[0] & /*isVerySmall*/ 32 && textPath_font_size_value !== (textPath_font_size_value = /*isVerySmall*/ ctx[5] ? "12px" : "15px")) {
				attr(textPath, "font-size", textPath_font_size_value);
			}
		},
		d(detaching) {
			if (detaching) detach(path);
			if (detaching) detach(g);
		}
	};
}

// (205:4) {#each data.salaries as d,i}
function create_each_block_2(ctx) {
	let rect0;
	let rect0_fill_value;
	let rect0_width_value;
	let rect0_height_value;
	let rect0_transform_value;
	let rect1;
	let rect1_fill_value;
	let rect1_width_value;
	let rect1_height_value;
	let rect1_transform_value;
	let rect2;
	let rect2_fill_value;
	let rect2_width_value;
	let rect2_height_value;
	let rect2_transform_value;
	let mounted;
	let dispose;

	function mouseover_handler(...args) {
		return /*mouseover_handler*/ ctx[19](/*d*/ ctx[30], ...args);
	}

	function mousemove_handler(...args) {
		return /*mousemove_handler*/ ctx[20](/*d*/ ctx[30], ...args);
	}

	function mouseover_handler_1(...args) {
		return /*mouseover_handler_1*/ ctx[21](/*d*/ ctx[30], ...args);
	}

	function mousemove_handler_1(...args) {
		return /*mousemove_handler_1*/ ctx[22](/*d*/ ctx[30], ...args);
	}

	function mouseover_handler_2(...args) {
		return /*mouseover_handler_2*/ ctx[23](/*d*/ ctx[30], ...args);
	}

	function mousemove_handler_2(...args) {
		return /*mousemove_handler_2*/ ctx[24](/*d*/ ctx[30], ...args);
	}

	return {
		c() {
			rect0 = svg_element("rect");
			rect1 = svg_element("rect");
			rect2 = svg_element("rect");
			attr(rect0, "class", "lower_bounds svelte-9sx8j8");
			attr(rect0, "fill", rect0_fill_value = color_skills(/*d*/ ctx[30].broad_skill_group));
			attr(rect0, "rx", "2px");
			attr(rect0, "ry", "2px");
			attr(rect0, "width", rect0_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.lower_bound.median - /*d*/ ctx[30].salaries.lower_bound.lower_q));
			attr(rect0, "height", rect0_height_value = /*$_xScaleS*/ ctx[3](2));
			attr(rect0, "transform", rect0_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.lower_q) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.lower_q) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t");
			attr(rect1, "class", "medians svelte-9sx8j8");
			attr(rect1, "fill", rect1_fill_value = color_skills(/*d*/ ctx[30].broad_skill_group));
			attr(rect1, "rx", "2px");
			attr(rect1, "ry", "2px");
			attr(rect1, "width", rect1_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.upper_bound.median - /*d*/ ctx[30].salaries.lower_bound.median));
			attr(rect1, "height", rect1_height_value = /*$_xScaleS*/ ctx[3](2));
			attr(rect1, "transform", rect1_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t");
			attr(rect2, "class", "upper_bounds svelte-9sx8j8");
			attr(rect2, "fill", rect2_fill_value = color_skills(/*d*/ ctx[30].broad_skill_group));
			attr(rect2, "rx", "2px");
			attr(rect2, "ry", "2px");
			attr(rect2, "width", rect2_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.upper_bound.upper_q - /*d*/ ctx[30].salaries.upper_bound.median));
			attr(rect2, "height", rect2_height_value = /*$_xScaleS*/ ctx[3](2));
			attr(rect2, "transform", rect2_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t");
		},
		m(target, anchor) {
			insert(target, rect0, anchor);
			insert(target, rect1, anchor);
			insert(target, rect2, anchor);

			if (!mounted) {
				dispose = [
					listen(rect0, "mouseover", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mouseover_handler : null)) (!/*isVerySmall*/ ctx[5] ? mouseover_handler : null).apply(this, arguments);
					}),
					listen(rect0, "mousemove", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mousemove_handler : null)) (!/*isVerySmall*/ ctx[5] ? mousemove_handler : null).apply(this, arguments);
					}),
					listen(rect0, "mouseout", /*onMouseout*/ ctx[16]),
					listen(rect1, "mouseover", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mouseover_handler_1 : null)) (!/*isVerySmall*/ ctx[5] ? mouseover_handler_1 : null).apply(this, arguments);
					}),
					listen(rect1, "mousemove", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mousemove_handler_1 : null)) (!/*isVerySmall*/ ctx[5] ? mousemove_handler_1 : null).apply(this, arguments);
					}),
					listen(rect1, "mouseout", /*onMouseout*/ ctx[16]),
					listen(rect2, "mouseover", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mouseover_handler_2 : null)) (!/*isVerySmall*/ ctx[5] ? mouseover_handler_2 : null).apply(this, arguments);
					}),
					listen(rect2, "mousemove", function () {
						if (is_function(!/*isVerySmall*/ ctx[5] ? mousemove_handler_2 : null)) (!/*isVerySmall*/ ctx[5] ? mousemove_handler_2 : null).apply(this, arguments);
					}),
					listen(rect2, "mouseout", /*onMouseout*/ ctx[16])
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*yScale_s*/ 4 && rect0_width_value !== (rect0_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.lower_bound.median - /*d*/ ctx[30].salaries.lower_bound.lower_q))) {
				attr(rect0, "width", rect0_width_value);
			}

			if (dirty[0] & /*$_xScaleS*/ 8 && rect0_height_value !== (rect0_height_value = /*$_xScaleS*/ ctx[3](2))) {
				attr(rect0, "height", rect0_height_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && rect0_transform_value !== (rect0_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.lower_q) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.lower_q) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t")) {
				attr(rect0, "transform", rect0_transform_value);
			}

			if (dirty[0] & /*yScale_s*/ 4 && rect1_width_value !== (rect1_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.upper_bound.median - /*d*/ ctx[30].salaries.lower_bound.median))) {
				attr(rect1, "width", rect1_width_value);
			}

			if (dirty[0] & /*$_xScaleS*/ 8 && rect1_height_value !== (rect1_height_value = /*$_xScaleS*/ ctx[3](2))) {
				attr(rect1, "height", rect1_height_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && rect1_transform_value !== (rect1_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.lower_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t")) {
				attr(rect1, "transform", rect1_transform_value);
			}

			if (dirty[0] & /*yScale_s*/ 4 && rect2_width_value !== (rect2_width_value = /*yScale_s*/ ctx[2](/*lowest_salary_shown*/ ctx[14] + /*d*/ ctx[30].salaries.upper_bound.upper_q - /*d*/ ctx[30].salaries.upper_bound.median))) {
				attr(rect2, "width", rect2_width_value);
			}

			if (dirty[0] & /*$_xScaleS*/ 8 && rect2_height_value !== (rect2_height_value = /*$_xScaleS*/ ctx[3](2))) {
				attr(rect2, "height", rect2_height_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && rect2_transform_value !== (rect2_transform_value = "\n\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t")) {
				attr(rect2, "transform", rect2_transform_value);
			}
		},
		d(detaching) {
			if (detaching) detach(rect0);
			if (detaching) detach(rect1);
			if (detaching) detach(rect2);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (371:5) {:else}
function create_else_block(ctx) {
	let text0;
	let t0_value = /*d*/ ctx[30].narrow_skill_group + "";
	let t0;
	let text0_transform_value;
	let text0_opacity_value;
	let text1;
	let t1_value = /*d*/ ctx[30].narrow_skill_group + "";
	let t1;
	let text1_transform_value;
	let text1_opacity_value;

	return {
		c() {
			text0 = svg_element("text");
			t0 = text(t0_value);
			text1 = svg_element("text");
			t1 = text(t1_value);
			attr(text0, "class", "salary_skills_name_bground svelte-9sx8j8");
			attr(text0, "fill", color_salary_names_bground);
			attr(text0, "stroke", color_salary_names_bground);
			attr(text0, "stroke-width", thickStroke);
			attr(text0, "transform", text0_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t\t");
			attr(text0, "dy", "1.1em");
			attr(text0, "dx", "0.2em");
			attr(text0, "text-anchor", "start");

			attr(text0, "opacity", text0_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0);

			attr(text1, "class", "salary_skills_name svelte-9sx8j8");
			attr(text1, "fill", color_salary_names);
			attr(text1, "transform", text1_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t\t");
			attr(text1, "dy", "1.1em");
			attr(text1, "dx", "0.2em");
			attr(text1, "text-anchor", "start");

			attr(text1, "opacity", text1_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0);
		},
		m(target, anchor) {
			insert(target, text0, anchor);
			append(text0, t0);
			insert(target, text1, anchor);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text0_transform_value !== (text0_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t\t")) {
				attr(text0, "transform", text0_transform_value);
			}

			if (dirty[0] & /*width*/ 1 && text0_opacity_value !== (text0_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0)) {
				attr(text0, "opacity", text0_opacity_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text1_transform_value !== (text1_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + 360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) + ")\n\t\t\t\t\t\t\t")) {
				attr(text1, "transform", text1_transform_value);
			}

			if (dirty[0] & /*width*/ 1 && text1_opacity_value !== (text1_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0)) {
				attr(text1, "opacity", text1_opacity_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text0);
			if (detaching) detach(text1);
		}
	};
}

// (312:5) {#if (360*((d.index-rotate_adj)/no_salaries)>90)}
function create_if_block_1(ctx) {
	let text0;
	let t0_value = /*d*/ ctx[30].narrow_skill_group + "";
	let t0;
	let text0_transform_value;
	let text0_opacity_value;
	let text1;
	let t1_value = /*d*/ ctx[30].narrow_skill_group + "";
	let t1;
	let text1_transform_value;
	let text1_opacity_value;

	return {
		c() {
			text0 = svg_element("text");
			t0 = text(t0_value);
			text1 = svg_element("text");
			t1 = text(t1_value);
			attr(text0, "class", "salary_skills_name_bground svelte-9sx8j8");
			attr(text0, "fill", color_salary_names_bground);
			attr(text0, "stroke", color_salary_names_bground);
			attr(text0, "stroke-width", thickStroke);
			attr(text0, "transform", text0_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + (360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) - 180) + ")\n\t\t\t\t\t\t\t");
			attr(text0, "dy", "-0.5em");
			attr(text0, "dx", "-0.2em");
			attr(text0, "text-anchor", "end");

			attr(text0, "opacity", text0_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0);

			attr(text1, "class", "salary_skills_name svelte-9sx8j8");
			attr(text1, "fill", color_salary_names);
			attr(text1, "transform", text1_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + (360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) - 180) + ")\n\t\t\t\t\t\t\t");
			attr(text1, "dy", "-0.5em");
			attr(text1, "dx", "-0.2em");
			attr(text1, "text-anchor", "end");

			attr(text1, "opacity", text1_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0);
		},
		m(target, anchor) {
			insert(target, text0, anchor);
			append(text0, t0);
			insert(target, text1, anchor);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text0_transform_value !== (text0_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + (360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) - 180) + ")\n\t\t\t\t\t\t\t")) {
				attr(text0, "transform", text0_transform_value);
			}

			if (dirty[0] & /*width*/ 1 && text0_opacity_value !== (text0_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0)) {
				attr(text0, "opacity", text0_opacity_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text1_transform_value !== (text1_transform_value = "\n\t\t\t\t\t\t\t\ttranslate(\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.cos(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + ",\n\t\t\t\t\t\t\t\t\t" + (/*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30].salaries.upper_bound.median) * Math.sin(2 * Math.PI * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]))) + "\n\t\t\t\t\t\t\t\t)\n\t\t\t\t\t\t\t\trotate(" + (360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) - 180) + ")\n\t\t\t\t\t\t\t")) {
				attr(text1, "transform", text1_transform_value);
			}

			if (dirty[0] & /*width*/ 1 && text1_opacity_value !== (text1_opacity_value = /*width*/ ctx[0] < 750
			? 0
			: /*i*/ ctx[32] % 3 == 0 ? 1 : 0)) {
				attr(text1, "opacity", text1_opacity_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text0);
			if (detaching) detach(text1);
		}
	};
}

// (309:4) {#each data.salaries as d,i}
function create_each_block_1(ctx) {
	let if_block_anchor;

	function select_block_type(ctx, dirty) {
		if (360 * ((/*d*/ ctx[30].index - /*rotate_adj*/ ctx[10]) / /*no_salaries*/ ctx[9]) > 90) return create_if_block_1;
		return create_else_block;
	}

	let current_block_type = select_block_type(ctx, [-1]);
	let if_block = current_block_type(ctx);

	return {
		c() {
			if_block.c();
			if_block_anchor = empty();
		},
		m(target, anchor) {
			if_block.m(target, anchor);
			insert(target, if_block_anchor, anchor);
		},
		p(ctx, dirty) {
			if_block.p(ctx, dirty);
		},
		d(detaching) {
			if_block.d(detaching);
			if (detaching) detach(if_block_anchor);
		}
	};
}

// (434:4) {#each salary_rings as d,i}
function create_each_block(ctx) {
	let text_1;

	let t_value = (/*i*/ ctx[32] == /*salary_rings*/ ctx[11].length - 1
	? "£" + /*d*/ ctx[30].toLocaleString() + "k per annum"
	: "£" + /*d*/ ctx[30].toLocaleString() + "k") + "";

	let t;
	let text_1_x_value;
	let text_1_dx_value;
	let text_1_y_value;
	let text_1_font_size_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			attr(text_1, "class", "salary_rings_text svelte-9sx8j8");
			attr(text_1, "fill", color_salary_rings);
			attr(text_1, "x", text_1_x_value = /*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30]) * Math.cos(1.5 * Math.PI));
			attr(text_1, "dx", text_1_dx_value = "-1em");
			attr(text_1, "y", text_1_y_value = /*$_xScaleS*/ ctx[3](49.75) + /*yScale_s*/ ctx[2](/*d*/ ctx[30]) * Math.sin(1.5 * Math.PI));
			attr(text_1, "font-size", text_1_font_size_value = /*isVerySmall*/ ctx[5] ? "10px" : "13px");
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text_1_x_value !== (text_1_x_value = /*$_xScaleS*/ ctx[3](50) + /*yScale_s*/ ctx[2](/*d*/ ctx[30]) * Math.cos(1.5 * Math.PI))) {
				attr(text_1, "x", text_1_x_value);
			}

			if (dirty[0] & /*$_xScaleS, yScale_s*/ 12 && text_1_y_value !== (text_1_y_value = /*$_xScaleS*/ ctx[3](49.75) + /*yScale_s*/ ctx[2](/*d*/ ctx[30]) * Math.sin(1.5 * Math.PI))) {
				attr(text_1, "y", text_1_y_value);
			}

			if (dirty[0] & /*isVerySmall*/ 32 && text_1_font_size_value !== (text_1_font_size_value = /*isVerySmall*/ ctx[5] ? "10px" : "13px")) {
				attr(text_1, "font-size", text_1_font_size_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
		}
	};
}

function create_fragment(ctx) {
	let div0;
	let p0;
	let span0;
	let t0;
	let t1;
	let span1;
	let t2_value = /*$_tooltip*/ ctx[8].name + "";
	let t2;
	let t3;
	let p1;
	let t5;
	let p2;
	let span3;
	let t6_value = /*$_tooltip*/ ctx[8].data_1 + "";
	let t6;
	let t7;
	let p3;
	let span4;
	let t8_value = /*$_tooltip*/ ctx[8].data_2 + "";
	let t8;
	let t9;
	let div1;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[4] && create_if_block(ctx);

	return {
		c() {
			div0 = element("div");
			p0 = element("p");
			span0 = element("span");
			t0 = text("    ");
			t1 = space();
			span1 = element("span");
			t2 = text(t2_value);
			t3 = space();
			p1 = element("p");
			p1.innerHTML = `<span class="line2_text svelte-9sx8j8">Annualised salary ranges (MIN - MAX):</span>`;
			t5 = space();
			p2 = element("p");
			span3 = element("span");
			t6 = text(t6_value);
			t7 = space();
			p3 = element("p");
			span4 = element("span");
			t8 = text(t8_value);
			t9 = space();
			div1 = element("div");
			if (if_block) if_block.c();
			set_style(span0, "background-color", /*$_tooltip*/ ctx[8].background_color);
			set_style(span0, "border-radius", "4px");
			set_style(span0, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_color);
			attr(span1, "class", "line1_text svelte-9sx8j8");
			attr(p0, "class", "svelte-9sx8j8");
			attr(p1, "class", "svelte-9sx8j8");
			attr(span3, "class", "line3_text");
			attr(p2, "class", "svelte-9sx8j8");
			attr(span4, "class", "line4_text");
			attr(p3, "class", "svelte-9sx8j8");
			attr(div0, "class", "tooltip svelte-9sx8j8");
			set_style(div0, "left", /*$_tooltip*/ ctx[8].left_margin);
			set_style(div0, "top", /*$_tooltip*/ ctx[8].top_margin);
			toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[8].isVisible);
			attr(div1, "class", "div_background svelte-9sx8j8");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, p0);
			append(p0, span0);
			append(span0, t0);
			append(p0, t1);
			append(p0, span1);
			append(span1, t2);
			append(div0, t3);
			append(div0, p1);
			append(div0, t5);
			append(div0, p2);
			append(p2, span3);
			append(span3, t6);
			append(div0, t7);
			append(div0, p3);
			append(p3, span4);
			append(span4, t8);
			insert(target, t9, anchor);
			insert(target, div1, anchor);
			if (if_block) if_block.m(div1, null);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span0, "background-color", /*$_tooltip*/ ctx[8].background_color);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span0, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_color);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t2_value !== (t2_value = /*$_tooltip*/ ctx[8].name + "")) set_data(t2, t2_value);
			if (dirty[0] & /*$_tooltip*/ 256 && t6_value !== (t6_value = /*$_tooltip*/ ctx[8].data_1 + "")) set_data(t6, t6_value);
			if (dirty[0] & /*$_tooltip*/ 256 && t8_value !== (t8_value = /*$_tooltip*/ ctx[8].data_2 + "")) set_data(t8, t8_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(div0, "left", /*$_tooltip*/ ctx[8].left_margin);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(div0, "top", /*$_tooltip*/ ctx[8].top_margin);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[8].isVisible);
			}

			if (/*width*/ ctx[0] && /*height*/ ctx[4]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t9);
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

const very_small_width = 450;

// Stroke widths
const thinStroke = "1px";

const thickStroke = "5px";

/* functions to position high and low salary labels */
// Convert polar to cartesian co-ordainations 
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	let angleInRadians = (angleInDegrees - 90) * Math.PI / 180;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians)
	};
}

// Arc that describes labels
function describeArc(x, y, radius, startAngle, endAngle) {
	let start = polarToCartesian(x, y, radius, endAngle);
	let end = polarToCartesian(x, y, radius, startAngle);
	let d = ["M", start.x, start.y, "A", radius, radius, 0, 1, 1, end.x, end.y].join(" ");
	return d;
}

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isVerySmall;
	let yScale_s;
	let salary_labels_radius;
	let makeArc;
	let format_text;
	let $_width;
	let $_margin;
	let $_xScaleS;
	let $_bodyWidthM;
	let $_tooltip;
	component_subscribe($$self, _width, $$value => $$invalidate(18, $_width = $$value));
	component_subscribe($$self, _margin, $$value => $$invalidate(1, $_margin = $$value));
	component_subscribe($$self, _xScaleS, $$value => $$invalidate(3, $_xScaleS = $$value));
	component_subscribe($$self, _bodyWidthM, $$value => $$invalidate(26, $_bodyWidthM = $$value));
	let no_salaries = data.salaries.length + 2;

	// Rotate the chart to ensure the min and max salaries straddle the top of the chart
	let rotate_adj = 0.215 * no_salaries;

	// Salaries for rings
	let salary_rings = [10, 20, 30, 40, 50, 60, 70];

	// Salary labels
	let salary_labels = ["Higher salaries", "Lower salaries"];

	// Start angles for salary labels
	let salary_labels_start_angle = [136, 200];

	// Calculate domain for salary scale (yScale_s)
	// -5 and +3 to give extra space
	let lowest_salary = Math.min.apply(Math, data.salaries.map(d => d.salaries.lower_bound.lower_q));

	let lowest_salary_shown = Math.min(salary_rings[0], lowest_salary) - 5;
	let highest_salary = Math.max.apply(Math, data.salaries.map(d => d.salaries.upper_bound.upper_q));
	let highest_salary_shown = Math.max(highest_salary, salary_rings[salary_rings.length - 1]) + 3;
	const _tooltip = writable({ isVisible: false });
	component_subscribe($$self, _tooltip, value => $$invalidate(8, $_tooltip = value));

	const onMouseout = () => {
		_tooltip.set({ isVisible: false });
	};

	const showTooltip = (d, event) => {
		const data_1_input = d.salaries.lower_bound.median;
		const data_2_input = d.salaries.upper_bound.median;

		const left = $_bodyWidthM == 0
		? event.pageX
		: $_xScaleS(50) + yScale_s(d.salaries.lower_bound.median) * Math.cos(2 * Math.PI * ((d.index - rotate_adj) / no_salaries));

		const top = event.pageY - 380;

		_tooltip.set({
			isVisible: true,
			left_margin: left + "px",
			top_margin: top + "px",
			background_color: color_skills(d.broad_skill_group),
			data_1: format_text("Median of MIN salaries", data_1_input),
			data_2: format_text("Median of MAX salaries", data_2_input),
			name: d.narrow_skill_group
		});
	};

	const mouseover_handler = (d, event) => showTooltip(d, event);
	const mousemove_handler = (d, isevent) => showTooltip(d, event);
	const mouseover_handler_1 = (d, isevent) => showTooltip(d, event);
	const mousemove_handler_1 = (d, isevent) => showTooltip(d, event);
	const mouseover_handler_2 = (d, isevent) => showTooltip(d, event);
	const mousemove_handler_2 = (d, isevent) => showTooltip(d, event);

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$_width, $_margin*/ 262146) {
			/* reactive vars */
			$: $$invalidate(0, width = $_width + $_margin.left + $_margin.right);
		}

		if ($$self.$$.dirty[0] & /*$_width, $_margin*/ 262146) {
			$: $$invalidate(4, height = $_width + $_margin.top + $_margin.bottom);
		}

		if ($$self.$$.dirty[0] & /*width*/ 1) {
			$: $$invalidate(5, isVerySmall = width < very_small_width);
		}

		if ($$self.$$.dirty[0] & /*width, $_margin*/ 3) {
			$: $$invalidate(2, yScale_s = scaleLinear().domain([lowest_salary_shown, highest_salary_shown]).range([0, 0.5 * (width - $_margin.left - $_margin.right)]));
		}

		if ($$self.$$.dirty[0] & /*yScale_s*/ 4) {
			// Radius for salary labels
			$: $$invalidate(6, salary_labels_radius = [yScale_s(72), yScale_s(52)]);
		}

		if ($$self.$$.dirty[0] & /*$_xScaleS*/ 8) {
			// Make arc
			$: $$invalidate(7, makeArc = (startAngle, radius_circle) => {
				let x = $_xScaleS(50);
				let y = $_xScaleS(50);
				let endAngle = startAngle - 359;
				return describeArc(x, y, radius_circle, startAngle, endAngle);
			});
		}
	};

	$: format_text = (text, d) => {
		return text + ": £" + Math.round(d).toLocaleString() + "k pa";
	};

	return [
		width,
		$_margin,
		yScale_s,
		$_xScaleS,
		height,
		isVerySmall,
		salary_labels_radius,
		makeArc,
		$_tooltip,
		no_salaries,
		rotate_adj,
		salary_rings,
		salary_labels,
		salary_labels_start_angle,
		lowest_salary_shown,
		_tooltip,
		onMouseout,
		showTooltip,
		$_width,
		mouseover_handler,
		mousemove_handler,
		mouseover_handler_1,
		mousemove_handler_1,
		mouseover_handler_2,
		mousemove_handler_2
	];
}

class ChartSalaries extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, [-1, -1]);
	}
}

export default ChartSalaries;