/* components/Nesta/Footer.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	detach,
	element,
	init,
	insert,
	noop,
	safe_not_equal,
	set_data,
	space,
	text
} from "svelte/internal";

function create_fragment(ctx) {
	let div7;
	let div6;
	let div3;
	let t11;
	let div4;
	let t12;
	let t13;
	let t14;
	let t15;
	let div5;

	return {
		c() {
			div7 = element("div");
			div6 = element("div");
			div3 = element("div");

			div3.innerHTML = `<div class="footer-links svelte-1rxdb2k"><div class="footer-links__container svelte-1rxdb2k"><div class="footer-link-item svelte-1rxdb2k"><a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/press/" target="_parent">Press</a> 
						<a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/jobs/" target="_parent">Jobs</a> 
						<a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/privacy/" target="_parent">Privacy</a> 
						<a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/cookies/" target="_parent">Cookies</a> 
						<a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/terms-use/" target="_parent">Terms of use</a> 
						<a class="footer-link-item__link svelte-1rxdb2k" href="https://www.nesta.org.uk/contact-us/" target="_parent">Contact us</a></div></div></div>`;

			t11 = space();
			div4 = element("div");
			t12 = text("© ");
			t13 = text(/*year*/ ctx[0]);
			t14 = text(" Nesta Nesta is a registered charity in England and Wales 1144091 and Scotland SC042833. Our main address is 58 Victoria Embankment, London, EC4Y 0DS. You can reach us by phone on 020 7438 2500 or drop us a line at information@nesta.org.uk.");
			t15 = space();
			div5 = element("div");
			div5.textContent = "All our work is licensed under a Creative Commons Attribution-NonCommercial-ShareAlike 4.0 International License, unless it says otherwise. We hope you find it useful.";
			attr(div3, "class", "app-footer__icons svelte-1rxdb2k");
			attr(div4, "class", "footer-link-item svelte-1rxdb2k");
			attr(div5, "class", "footer-link-item svelte-1rxdb2k");
			attr(div6, "class", "app-footer__container svelte-1rxdb2k");
			attr(div7, "class", "app-footer svelte-1rxdb2k");
		},
		m(target, anchor) {
			insert(target, div7, anchor);
			append(div7, div6);
			append(div6, div3);
			append(div6, t11);
			append(div6, div4);
			append(div4, t12);
			append(div4, t13);
			append(div4, t14);
			append(div6, t15);
			append(div6, div5);
		},
		p(ctx, [dirty]) {
			if (dirty & /*year*/ 1) set_data(t13, /*year*/ ctx[0]);
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div7);
		}
	};
}

function instance($$self, $$props, $$invalidate) {
	let { year = 2022 } = $$props;

	$$self.$$set = $$props => {
		if ("year" in $$props) $$invalidate(0, year = $$props.year);
	};

	return [year];
}

class Footer extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, { year: 0 });
	}
}

export default Footer;