/* components/ChartOccupations.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	svg_element,
	text,
	toggle_class
} from "svelte/internal";

import data from "../../data/data.json";

import {
	color_occup_rect,
	color_occup_skill_names,
	color_occup_skill_names_bground,
	color_skills,
	color_tooltip_bground
} from "../shared/colours";

import { chartsHeight } from "../shared/geometry";
import { _margin, _width, _xScale } from "../stores";
import { sankey, sankeyLinkHorizontal } from "d3-sankey";
import { writable } from "svelte/store";
import { pointer } from "d3-selection";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[16] = list[i];
	return child_ctx;
}

// (200:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let text0;
	let t0;
	let text0_x_value;
	let text0_y_value;
	let text0_font_size_value;
	let text1;
	let t1;
	let text1_x_value;
	let text1_y_value;
	let text1_font_size_value;
	let each0_anchor;
	let g_transform_value;
	let each_value_1 = /*layout*/ ctx[5].links;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*layout*/ ctx[5].nodes;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");
			text0 = svg_element("text");
			t0 = text("Occupations\n\t\t\t\t");
			text1 = svg_element("text");
			t1 = text("Skill groups\n\t\t\t\t");

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each0_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(text0, "class", "left_title svelte-x3ms8e");
			attr(text0, "text-anchor", "start");
			attr(text0, "fill", color_occup_skill_names);
			attr(text0, "x", text0_x_value = 1);
			attr(text0, "y", text0_y_value = 20);
			attr(text0, "font-size", text0_font_size_value = /*isVerySmall*/ ctx[4] ? "13px" : "16px");
			attr(text1, "class", "right_title svelte-x3ms8e");
			attr(text1, "text-anchor", "end");
			attr(text1, "fill", color_occup_skill_names);
			attr(text1, "x", text1_x_value = /*$_width*/ ctx[1] - 1);
			attr(text1, "y", text1_y_value = 20);
			attr(text1, "font-size", text1_font_size_value = /*isVerySmall*/ ctx[4] ? "13px" : "16px");
			attr(g, "transform", g_transform_value = "translate(" + /*$_margin*/ ctx[2].left + "," + /*$_margin*/ ctx[2].top + ")");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);
			append(g, text0);
			append(text0, t0);
			append(g, text1);
			append(text1, t1);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, each0_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*isVerySmall*/ 16 && text0_font_size_value !== (text0_font_size_value = /*isVerySmall*/ ctx[4] ? "13px" : "16px")) {
				attr(text0, "font-size", text0_font_size_value);
			}

			if (dirty & /*$_width*/ 2 && text1_x_value !== (text1_x_value = /*$_width*/ ctx[1] - 1)) {
				attr(text1, "x", text1_x_value);
			}

			if (dirty & /*isVerySmall*/ 16 && text1_font_size_value !== (text1_font_size_value = /*isVerySmall*/ ctx[4] ? "13px" : "16px")) {
				attr(text1, "font-size", text1_font_size_value);
			}

			if (dirty & /*color_skills, layout, sankeyLinkHorizontal, isVerySmall, showTooltip, event, onMouseout*/ 816) {
				each_value_1 = /*layout*/ ctx[5].links;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, each0_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*color_occup_skill_names, layout, color_occup_skill_names_bground, thickStroke, color_occup_rect*/ 32) {
				each_value = /*layout*/ ctx[5].nodes;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$_margin*/ 4 && g_transform_value !== (g_transform_value = "translate(" + /*$_margin*/ ctx[2].left + "," + /*$_margin*/ ctx[2].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty & /*height*/ 8) {
				attr(svg, "height", /*height*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (229:4) {#each layout.links as d}
function create_each_block_1(ctx) {
	let path;
	let path_stroke_value;
	let path_d_value;
	let path_stroke_width_value;
	let mounted;
	let dispose;

	function mouseover_handler(...args) {
		return /*mouseover_handler*/ ctx[11](/*d*/ ctx[16], ...args);
	}

	function mousemove_handler(...args) {
		return /*mousemove_handler*/ ctx[12](/*d*/ ctx[16], ...args);
	}

	return {
		c() {
			path = svg_element("path");
			attr(path, "class", "occupation_skill_links svelte-x3ms8e");
			attr(path, "stroke", path_stroke_value = color_skills(/*d*/ ctx[16].target.name));
			attr(path, "d", path_d_value = sankeyLinkHorizontal()(/*d*/ ctx[16]));
			attr(path, "stroke-width", path_stroke_width_value = /*d*/ ctx[16].width);
		},
		m(target, anchor) {
			insert(target, path, anchor);

			if (!mounted) {
				dispose = [
					listen(path, "mouseover", function () {
						if (is_function(!/*isVerySmall*/ ctx[4] ? mouseover_handler : null)) (!/*isVerySmall*/ ctx[4] ? mouseover_handler : null).apply(this, arguments);
					}),
					listen(path, "mousemove", function () {
						if (is_function(!/*isVerySmall*/ ctx[4] ? mousemove_handler : null)) (!/*isVerySmall*/ ctx[4] ? mousemove_handler : null).apply(this, arguments);
					}),
					listen(path, "mouseout", /*onMouseout*/ ctx[8])
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty & /*layout*/ 32 && path_stroke_value !== (path_stroke_value = color_skills(/*d*/ ctx[16].target.name))) {
				attr(path, "stroke", path_stroke_value);
			}

			if (dirty & /*layout*/ 32 && path_d_value !== (path_d_value = sankeyLinkHorizontal()(/*d*/ ctx[16]))) {
				attr(path, "d", path_d_value);
			}

			if (dirty & /*layout*/ 32 && path_stroke_width_value !== (path_stroke_width_value = /*d*/ ctx[16].width)) {
				attr(path, "stroke-width", path_stroke_width_value);
			}
		},
		d(detaching) {
			if (detaching) detach(path);
			mounted = false;
			run_all(dispose);
		}
	};
}

// (243:4) {#each layout.nodes as d}
function create_each_block(ctx) {
	let rect;
	let rect_x_value;
	let rect_y_value;
	let rect_width_value;
	let rect_height_value;
	let text0;
	let t0_value = /*d*/ ctx[16].name.replace(" AND ", " & ") + "";
	let t0;
	let text0_x_value;
	let text0_y_value;
	let text0_text_anchor_value;
	let text1;
	let t1_value = /*d*/ ctx[16].name.replace(" AND ", " & ") + "";
	let t1;
	let text1_x_value;
	let text1_y_value;
	let text1_text_anchor_value;

	return {
		c() {
			rect = svg_element("rect");
			text0 = svg_element("text");
			t0 = text(t0_value);
			text1 = svg_element("text");
			t1 = text(t1_value);
			attr(rect, "class", "occupation_skill_rect");
			attr(rect, "fill", color_occup_rect);
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
			attr(rect, "x", rect_x_value = /*d*/ ctx[16].x0);
			attr(rect, "y", rect_y_value = /*d*/ ctx[16].y0);
			attr(rect, "width", rect_width_value = /*d*/ ctx[16].x1 - /*d*/ ctx[16].x0);
			attr(rect, "height", rect_height_value = /*d*/ ctx[16].y1 - /*d*/ ctx[16].y0);
			attr(text0, "class", "occupation_skill_text_bground svelte-x3ms8e");
			attr(text0, "dy", "-0.2em");
			attr(text0, "fill", color_occup_skill_names_bground);
			attr(text0, "stroke", color_occup_skill_names_bground);
			attr(text0, "stroke-width", thickStroke);
			attr(text0, "x", text0_x_value = /*d*/ ctx[16].x0);
			attr(text0, "y", text0_y_value = /*d*/ ctx[16].y0);
			attr(text0, "text-anchor", text0_text_anchor_value = /*d*/ ctx[16].targetLinks.length > 0 ? "end" : "start");
			attr(text1, "class", "occupation_skill_text svelte-x3ms8e");
			attr(text1, "dy", "-0.2em");
			attr(text1, "fill", color_occup_skill_names);
			attr(text1, "x", text1_x_value = /*d*/ ctx[16].x0);
			attr(text1, "y", text1_y_value = /*d*/ ctx[16].y0);
			attr(text1, "text-anchor", text1_text_anchor_value = /*d*/ ctx[16].targetLinks.length > 0 ? "end" : "start");
		},
		m(target, anchor) {
			insert(target, rect, anchor);
			insert(target, text0, anchor);
			append(text0, t0);
			insert(target, text1, anchor);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty & /*layout*/ 32 && rect_x_value !== (rect_x_value = /*d*/ ctx[16].x0)) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*layout*/ 32 && rect_y_value !== (rect_y_value = /*d*/ ctx[16].y0)) {
				attr(rect, "y", rect_y_value);
			}

			if (dirty & /*layout*/ 32 && rect_width_value !== (rect_width_value = /*d*/ ctx[16].x1 - /*d*/ ctx[16].x0)) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty & /*layout*/ 32 && rect_height_value !== (rect_height_value = /*d*/ ctx[16].y1 - /*d*/ ctx[16].y0)) {
				attr(rect, "height", rect_height_value);
			}

			if (dirty & /*layout*/ 32 && t0_value !== (t0_value = /*d*/ ctx[16].name.replace(" AND ", " & ") + "")) set_data(t0, t0_value);

			if (dirty & /*layout*/ 32 && text0_x_value !== (text0_x_value = /*d*/ ctx[16].x0)) {
				attr(text0, "x", text0_x_value);
			}

			if (dirty & /*layout*/ 32 && text0_y_value !== (text0_y_value = /*d*/ ctx[16].y0)) {
				attr(text0, "y", text0_y_value);
			}

			if (dirty & /*layout*/ 32 && text0_text_anchor_value !== (text0_text_anchor_value = /*d*/ ctx[16].targetLinks.length > 0 ? "end" : "start")) {
				attr(text0, "text-anchor", text0_text_anchor_value);
			}

			if (dirty & /*layout*/ 32 && t1_value !== (t1_value = /*d*/ ctx[16].name.replace(" AND ", " & ") + "")) set_data(t1, t1_value);

			if (dirty & /*layout*/ 32 && text1_x_value !== (text1_x_value = /*d*/ ctx[16].x0)) {
				attr(text1, "x", text1_x_value);
			}

			if (dirty & /*layout*/ 32 && text1_y_value !== (text1_y_value = /*d*/ ctx[16].y0)) {
				attr(text1, "y", text1_y_value);
			}

			if (dirty & /*layout*/ 32 && text1_text_anchor_value !== (text1_text_anchor_value = /*d*/ ctx[16].targetLinks.length > 0 ? "end" : "start")) {
				attr(text1, "text-anchor", text1_text_anchor_value);
			}
		},
		d(detaching) {
			if (detaching) detach(rect);
			if (detaching) detach(text0);
			if (detaching) detach(text1);
		}
	};
}

function create_fragment(ctx) {
	let div0;
	let p0;
	let span0;
	let t0_value = /*$_tooltip*/ ctx[6].name_1 + "";
	let t0;
	let t1;
	let p1;
	let span1;
	let t2;
	let t3;
	let span2;
	let t4_value = /*$_tooltip*/ ctx[6].skill_value_1 + "";
	let t4;
	let t5;
	let p2;
	let span3;
	let t6;
	let t7;
	let span4;
	let t8_value = /*$_tooltip*/ ctx[6].skill_value_2 + "";
	let t8;
	let t9;
	let p3;
	let span5;
	let t10;
	let t11;
	let span6;
	let t12_value = /*$_tooltip*/ ctx[6].skill_value_3 + "";
	let t12;
	let t13;
	let p4;
	let span7;
	let t14;
	let t15;
	let span8;
	let t16_value = /*$_tooltip*/ ctx[6].skill_value_4 + "";
	let t16;
	let t17;
	let p5;
	let span9;
	let t18;
	let t19;
	let span10;
	let t20_value = /*$_tooltip*/ ctx[6].skill_value_5 + "";
	let t20;
	let t21;
	let p6;
	let span11;
	let t22;
	let t23;
	let span12;
	let t24_value = /*$_tooltip*/ ctx[6].skill_value_6 + "";
	let t24;
	let t25;
	let p7;
	let span13;
	let t26;
	let t27;
	let span14;
	let t28_value = /*$_tooltip*/ ctx[6].skill_value_7 + "";
	let t28;
	let t29;
	let p8;
	let span15;
	let t30;
	let t31;
	let span16;
	let t32_value = /*$_tooltip*/ ctx[6].skill_value_8 + "";
	let t32;
	let t33;
	let div1;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div0 = element("div");
			p0 = element("p");
			span0 = element("span");
			t0 = text(t0_value);
			t1 = space();
			p1 = element("p");
			span1 = element("span");
			t2 = text("    ");
			t3 = space();
			span2 = element("span");
			t4 = text(t4_value);
			t5 = space();
			p2 = element("p");
			span3 = element("span");
			t6 = text("    ");
			t7 = space();
			span4 = element("span");
			t8 = text(t8_value);
			t9 = space();
			p3 = element("p");
			span5 = element("span");
			t10 = text("    ");
			t11 = space();
			span6 = element("span");
			t12 = text(t12_value);
			t13 = space();
			p4 = element("p");
			span7 = element("span");
			t14 = text("    ");
			t15 = space();
			span8 = element("span");
			t16 = text(t16_value);
			t17 = space();
			p5 = element("p");
			span9 = element("span");
			t18 = text("    ");
			t19 = space();
			span10 = element("span");
			t20 = text(t20_value);
			t21 = space();
			p6 = element("p");
			span11 = element("span");
			t22 = text("    ");
			t23 = space();
			span12 = element("span");
			t24 = text(t24_value);
			t25 = space();
			p7 = element("p");
			span13 = element("span");
			t26 = text("    ");
			t27 = space();
			span14 = element("span");
			t28 = text(t28_value);
			t29 = space();
			p8 = element("p");
			span15 = element("span");
			t30 = text("    ");
			t31 = space();
			span16 = element("span");
			t32 = text(t32_value);
			t33 = space();
			div1 = element("div");
			if (if_block) if_block.c();
			attr(span0, "class", "line1_text svelte-x3ms8e");
			attr(p0, "class", "line1_bground svelte-x3ms8e");
			set_style(span1, "background-color", /*$_tooltip*/ ctx[6].background_color_1);
			set_style(span1, "border-radius", "4px");
			set_style(span1, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_1);
			attr(p1, "class", "svelte-x3ms8e");
			set_style(span3, "background-color", /*$_tooltip*/ ctx[6].background_color_2);
			set_style(span3, "border-radius", "4px");
			set_style(span3, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_2);
			attr(p2, "class", "svelte-x3ms8e");
			set_style(span5, "background-color", /*$_tooltip*/ ctx[6].background_color_3);
			set_style(span5, "border-radius", "4px");
			set_style(span5, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_3);
			attr(p3, "class", "svelte-x3ms8e");
			set_style(span7, "background-color", /*$_tooltip*/ ctx[6].background_color_4);
			set_style(span7, "border-radius", "4px");
			set_style(span7, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_4);
			attr(p4, "class", "svelte-x3ms8e");
			set_style(span9, "background-color", /*$_tooltip*/ ctx[6].background_color_5);
			set_style(span9, "border-radius", "4px");
			set_style(span9, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_5);
			attr(p5, "class", "svelte-x3ms8e");
			set_style(span11, "background-color", /*$_tooltip*/ ctx[6].background_color_6);
			set_style(span11, "border-radius", "4px");
			set_style(span11, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_6);
			attr(p6, "class", "svelte-x3ms8e");
			set_style(span13, "background-color", /*$_tooltip*/ ctx[6].background_color_7);
			set_style(span13, "border-radius", "4px");
			set_style(span13, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_7);
			attr(p7, "class", "svelte-x3ms8e");
			set_style(span15, "background-color", /*$_tooltip*/ ctx[6].background_color_8);
			set_style(span15, "border-radius", "4px");
			set_style(span15, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_8);
			attr(p8, "class", "svelte-x3ms8e");
			attr(div0, "class", "tooltip svelte-x3ms8e");
			set_style(div0, "left", /*$_tooltip*/ ctx[6].left_margin);
			set_style(div0, "top", /*$_tooltip*/ ctx[6].top_margin);
			toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[6].isVisible);
			attr(div1, "class", "div_background svelte-x3ms8e");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, p0);
			append(p0, span0);
			append(span0, t0);
			append(div0, t1);
			append(div0, p1);
			append(p1, span1);
			append(span1, t2);
			append(p1, t3);
			append(p1, span2);
			append(span2, t4);
			append(div0, t5);
			append(div0, p2);
			append(p2, span3);
			append(span3, t6);
			append(p2, t7);
			append(p2, span4);
			append(span4, t8);
			append(div0, t9);
			append(div0, p3);
			append(p3, span5);
			append(span5, t10);
			append(p3, t11);
			append(p3, span6);
			append(span6, t12);
			append(div0, t13);
			append(div0, p4);
			append(p4, span7);
			append(span7, t14);
			append(p4, t15);
			append(p4, span8);
			append(span8, t16);
			append(div0, t17);
			append(div0, p5);
			append(p5, span9);
			append(span9, t18);
			append(p5, t19);
			append(p5, span10);
			append(span10, t20);
			append(div0, t21);
			append(div0, p6);
			append(p6, span11);
			append(span11, t22);
			append(p6, t23);
			append(p6, span12);
			append(span12, t24);
			append(div0, t25);
			append(div0, p7);
			append(p7, span13);
			append(span13, t26);
			append(p7, t27);
			append(p7, span14);
			append(span14, t28);
			append(div0, t29);
			append(div0, p8);
			append(p8, span15);
			append(span15, t30);
			append(p8, t31);
			append(p8, span16);
			append(span16, t32);
			insert(target, t33, anchor);
			insert(target, div1, anchor);
			if (if_block) if_block.m(div1, null);
		},
		p(ctx, [dirty]) {
			if (dirty & /*$_tooltip*/ 64 && t0_value !== (t0_value = /*$_tooltip*/ ctx[6].name_1 + "")) set_data(t0, t0_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span1, "background-color", /*$_tooltip*/ ctx[6].background_color_1);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span1, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_1);
			}

			if (dirty & /*$_tooltip*/ 64 && t4_value !== (t4_value = /*$_tooltip*/ ctx[6].skill_value_1 + "")) set_data(t4, t4_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span3, "background-color", /*$_tooltip*/ ctx[6].background_color_2);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span3, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_2);
			}

			if (dirty & /*$_tooltip*/ 64 && t8_value !== (t8_value = /*$_tooltip*/ ctx[6].skill_value_2 + "")) set_data(t8, t8_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span5, "background-color", /*$_tooltip*/ ctx[6].background_color_3);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span5, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_3);
			}

			if (dirty & /*$_tooltip*/ 64 && t12_value !== (t12_value = /*$_tooltip*/ ctx[6].skill_value_3 + "")) set_data(t12, t12_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span7, "background-color", /*$_tooltip*/ ctx[6].background_color_4);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span7, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_4);
			}

			if (dirty & /*$_tooltip*/ 64 && t16_value !== (t16_value = /*$_tooltip*/ ctx[6].skill_value_4 + "")) set_data(t16, t16_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span9, "background-color", /*$_tooltip*/ ctx[6].background_color_5);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span9, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_5);
			}

			if (dirty & /*$_tooltip*/ 64 && t20_value !== (t20_value = /*$_tooltip*/ ctx[6].skill_value_5 + "")) set_data(t20, t20_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span11, "background-color", /*$_tooltip*/ ctx[6].background_color_6);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span11, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_6);
			}

			if (dirty & /*$_tooltip*/ 64 && t24_value !== (t24_value = /*$_tooltip*/ ctx[6].skill_value_6 + "")) set_data(t24, t24_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span13, "background-color", /*$_tooltip*/ ctx[6].background_color_7);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span13, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_7);
			}

			if (dirty & /*$_tooltip*/ 64 && t28_value !== (t28_value = /*$_tooltip*/ ctx[6].skill_value_7 + "")) set_data(t28, t28_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span15, "background-color", /*$_tooltip*/ ctx[6].background_color_8);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(span15, "border", "2px solid " + /*$_tooltip*/ ctx[6].background_color_8);
			}

			if (dirty & /*$_tooltip*/ 64 && t32_value !== (t32_value = /*$_tooltip*/ ctx[6].skill_value_8 + "")) set_data(t32, t32_value);

			if (dirty & /*$_tooltip*/ 64) {
				set_style(div0, "left", /*$_tooltip*/ ctx[6].left_margin);
			}

			if (dirty & /*$_tooltip*/ 64) {
				set_style(div0, "top", /*$_tooltip*/ ctx[6].top_margin);
			}

			if (dirty & /*$_tooltip*/ 64) {
				toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[6].isVisible);
			}

			if (/*width*/ ctx[0] && /*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t33);
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

const thickStroke = "5px";

// For smaller screens
// (change layout of blocks)
const small_width = 850;

// For really small screens
// (stop showing mouseovers)
const very_small_width = 450;

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isVerySmall;
	let isSmall;
	let sankeyGenerator;
	let layout;
	let format_text;
	let $_width;
	let $_margin;
	let $_xScale;
	let $_tooltip;
	component_subscribe($$self, _width, $$value => $$invalidate(1, $_width = $$value));
	component_subscribe($$self, _margin, $$value => $$invalidate(2, $_margin = $$value));
	component_subscribe($$self, _xScale, $$value => $$invalidate(15, $_xScale = $$value));
	const _tooltip = writable({ isVisible: false });
	component_subscribe($$self, _tooltip, value => $$invalidate(6, $_tooltip = value));

	const onMouseout = () => {
		_tooltip.set({ isVisible: false });
	};

	const showTooltip = (d, event) => {
		const left = isSmall
		? Math.min($_xScale(85), pointer(event)[0])
		: Math.min($_xScale(95), pointer(event)[0]);

		const top = event.pageY - 380;
		var sourceLinks = d.source.sourceLinks;
		var no_links = sourceLinks.length;
		var skills_included = [];

		for (let i = 0; i < no_links; i++) {
			skills_included.push(d.source.sourceLinks[i].target.name);
		}

		var all_skills_names = [];
		var all_skills_values = [];

		for (let i = 0; i < color_skills.domain().length; i++) {
			var one_skill = color_skills.domain()[i];
			var index_skill = skills_included.indexOf(one_skill);

			var one_value = index_skill > -1
			? d.source.sourceLinks[index_skill].value
			: 0;

			all_skills_names.push(one_skill);
			all_skills_values.push(one_value);
		}

		_tooltip.set({
			isVisible: true,
			left_margin: left + "px",
			top_margin: top + "px",
			background_color: color_skills(d.target.name),
			data: format_text(d),
			name_1: d.source.name,
			skill_value_1: format_text(all_skills_names[0], all_skills_values[0]),
			background_color_1: color_skills(all_skills_names[0]),
			skill_value_2: format_text(all_skills_names[1], all_skills_values[1]),
			background_color_2: color_skills(all_skills_names[1]),
			skill_value_3: format_text(all_skills_names[2], all_skills_values[2]),
			background_color_3: color_skills(all_skills_names[2]),
			skill_value_4: format_text(all_skills_names[3], all_skills_values[3]),
			background_color_4: color_skills(all_skills_names[3]),
			skill_value_5: format_text(all_skills_names[4], all_skills_values[4]),
			background_color_5: color_skills(all_skills_names[4]),
			skill_value_6: format_text(all_skills_names[5], all_skills_values[5]),
			background_color_6: color_skills(all_skills_names[5]),
			skill_value_7: format_text(all_skills_names[6], all_skills_values[6]),
			background_color_7: color_skills(all_skills_names[6]),
			skill_value_8: format_text(all_skills_names[7], all_skills_values[7]),
			background_color_8: color_skills(all_skills_names[7])
		});
	};

	const mouseover_handler = (d, isevent) => showTooltip(d, event);
	const mousemove_handler = (d, isevent) => showTooltip(d, event);

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_width, $_margin*/ 6) {
			/* reactive vars */
			$: $$invalidate(0, width = $_width + $_margin.left + $_margin.right);
		}

		if ($$self.$$.dirty & /*$_margin*/ 4) {
			$: $$invalidate(3, height = chartsHeight + $_margin.top + $_margin.bottom);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: $$invalidate(4, isVerySmall = width < very_small_width);
		}

		if ($$self.$$.dirty & /*width*/ 1) {
			$: isSmall = width < small_width;
		}

		if ($$self.$$.dirty & /*$_width*/ 2) {
			$: $$invalidate(10, sankeyGenerator = sankey().nodeId(d => d.name).nodeWidth(3).nodePadding(11).extent([[1, 50], [$_width - 1, chartsHeight]]));
		}

		if ($$self.$$.dirty & /*sankeyGenerator*/ 1024) {
			$: $$invalidate(5, layout = sankeyGenerator({
				links: data.occupations.links,
				nodes: data.occupations.nodes
			}));
		}
	};

	$: format_text = (skill, value) => {
		// If demand for skill is >=1%
		if (value >= 1) {
			// Round to nearest integer
			return skill + ": " + Math.round(value) + "%";
		} else {
			return skill + ": <1%";
		}
	};

	return [
		width,
		$_width,
		$_margin,
		height,
		isVerySmall,
		layout,
		$_tooltip,
		_tooltip,
		onMouseout,
		showTooltip,
		sankeyGenerator,
		mouseover_handler,
		mousemove_handler
	];
}

class ChartOccupations extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default ChartOccupations;