/* components/ChartLocations.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	is_function,
	listen,
	noop,
	run_all,
	safe_not_equal,
	set_data,
	set_style,
	space,
	svg_element,
	text,
	toggle_class
} from "svelte/internal";

import data from "../../data/data.json";

import {
	color_nuts2_names_bground,
	color_nuts2_names,
	color_skills,
	color_tooltip_bground
} from "../shared/colours";

import { chartsHeight, yScale } from "../shared/geometry";
import { _height, _margin, _width, _xScale } from "../stores";
import { writable } from "svelte/store";
import { pointer } from "d3-selection";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[25] = list[i];
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[28] = list[i];
	child_ctx[30] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[28] = list[i];
	child_ctx[30] = i;
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[28] = list[i];
	return child_ctx;
}

// (212:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let g_transform_value;
	let each_value = data.locations;
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(g, "transform", g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")");
			attr(svg, "width", /*width*/ ctx[0]);
			attr(svg, "height", /*height*/ ctx[3]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty[0] & /*isSmall, $_xScale, noXpos, noYpos, isVerySmall, showTooltip, onMouseout, height_adj_nuts_text*/ 6900) {
				each_value = data.locations;
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty[0] & /*$_margin*/ 2 && g_transform_value !== (g_transform_value = "translate(" + /*$_margin*/ ctx[1].left + "," + /*$_margin*/ ctx[1].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty[0] & /*width*/ 1) {
				attr(svg, "width", /*width*/ ctx[0]);
			}

			if (dirty[0] & /*height*/ 8) {
				attr(svg, "height", /*height*/ ctx[3]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (228:6) {#each location.skills as d}
function create_each_block_3(ctx) {
	let rect;
	let rect_fill_value;
	let rect_height_value;
	let rect_x_value;
	let rect_y_value;
	let rect_width_value;

	return {
		c() {
			rect = svg_element("rect");
			attr(rect, "class", "nuts2_skills");
			attr(rect, "fill", rect_fill_value = color_skills(/*d*/ ctx[28].broad_skill_group));
			attr(rect, "height", rect_height_value = yScale(100 - prop_space_y * 100 / /*noYpos*/ ctx[6]));
			attr(rect, "x", rect_x_value = /*$_xScale*/ ctx[7](prop_space_x * /*d*/ ctx[28].cumulative_percent / /*noXpos*/ ctx[5] - prop_space_x * 50 / /*noXpos*/ ctx[5]));
			attr(rect, "y", rect_y_value = yScale(100 + prop_space_y * 50 / /*noYpos*/ ctx[6]));
			attr(rect, "width", rect_width_value = /*$_xScale*/ ctx[7](prop_space_x * /*d*/ ctx[28].percent / /*noXpos*/ ctx[5]));
			attr(rect, "rx", "4px");
			attr(rect, "ry", "4px");
		},
		m(target, anchor) {
			insert(target, rect, anchor);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*noYpos*/ 64 && rect_height_value !== (rect_height_value = yScale(100 - prop_space_y * 100 / /*noYpos*/ ctx[6]))) {
				attr(rect, "height", rect_height_value);
			}

			if (dirty[0] & /*$_xScale, noXpos*/ 160 && rect_x_value !== (rect_x_value = /*$_xScale*/ ctx[7](prop_space_x * /*d*/ ctx[28].cumulative_percent / /*noXpos*/ ctx[5] - prop_space_x * 50 / /*noXpos*/ ctx[5]))) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty[0] & /*noYpos*/ 64 && rect_y_value !== (rect_y_value = yScale(100 + prop_space_y * 50 / /*noYpos*/ ctx[6]))) {
				attr(rect, "y", rect_y_value);
			}

			if (dirty[0] & /*$_xScale, noXpos*/ 160 && rect_width_value !== (rect_width_value = /*$_xScale*/ ctx[7](prop_space_x * /*d*/ ctx[28].percent / /*noXpos*/ ctx[5]))) {
				attr(rect, "width", rect_width_value);
			}
		},
		d(detaching) {
			if (detaching) detach(rect);
		}
	};
}

// (245:6) {#each location.names_long as d, i}
function create_each_block_2(ctx) {
	let text0;
	let t0_value = /*d*/ ctx[28][0] + "";
	let t0;
	let text0_dy_value;
	let text0_y_value;
	let text0_opacity_value;
	let text1;
	let t1_value = /*d*/ ctx[28][0] + "";
	let t1;
	let text1_dy_value;
	let text1_y_value;
	let text1_opacity_value;

	return {
		c() {
			text0 = svg_element("text");
			t0 = text(t0_value);
			text1 = svg_element("text");
			t1 = text(t1_value);
			attr(text0, "class", "nuts2_names_long_bground svelte-10b1f5r");
			attr(text0, "dy", text0_dy_value = "" + (/*i*/ ctx[30] + "em"));
			attr(text0, "fill", color_nuts2_names);
			attr(text0, "stroke-width", strokeWidth);
			attr(text0, "stroke", color_nuts2_names_bground);
			attr(text0, "y", text0_y_value = /*height_adj_nuts_text*/ ctx[9][/*d*/ ctx[28][1] - 1]);
			attr(text0, "opacity", text0_opacity_value = /*isSmall*/ ctx[2] ? 0 : 1);
			attr(text1, "class", "nuts2_names_long svelte-10b1f5r");
			attr(text1, "dy", text1_dy_value = "" + (/*i*/ ctx[30] + "em"));
			attr(text1, "fill", color_nuts2_names);
			attr(text1, "y", text1_y_value = /*height_adj_nuts_text*/ ctx[9][/*d*/ ctx[28][1] - 1]);
			attr(text1, "opacity", text1_opacity_value = /*isSmall*/ ctx[2] ? 0 : 1);
		},
		m(target, anchor) {
			insert(target, text0, anchor);
			append(text0, t0);
			insert(target, text1, anchor);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*isSmall*/ 4 && text0_opacity_value !== (text0_opacity_value = /*isSmall*/ ctx[2] ? 0 : 1)) {
				attr(text0, "opacity", text0_opacity_value);
			}

			if (dirty[0] & /*isSmall*/ 4 && text1_opacity_value !== (text1_opacity_value = /*isSmall*/ ctx[2] ? 0 : 1)) {
				attr(text1, "opacity", text1_opacity_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text0);
			if (detaching) detach(text1);
		}
	};
}

// (273:6) {#each location.names_short as d, i}
function create_each_block_1(ctx) {
	let text0;
	let t0_value = /*d*/ ctx[28][0] + "";
	let t0;
	let text0_dy_value;
	let text0_y_value;
	let text0_opacity_value;
	let text1;
	let t1_value = /*d*/ ctx[28][0] + "";
	let t1;
	let text1_dy_value;
	let text1_y_value;
	let text1_opacity_value;

	return {
		c() {
			text0 = svg_element("text");
			t0 = text(t0_value);
			text1 = svg_element("text");
			t1 = text(t1_value);
			attr(text0, "class", "nuts2_names_short_bground svelte-10b1f5r");
			attr(text0, "dy", text0_dy_value = "" + (/*i*/ ctx[30] + "em"));
			attr(text0, "fill", color_nuts2_names);
			attr(text0, "stroke-width", strokeWidth);
			attr(text0, "stroke", color_nuts2_names_bground);
			attr(text0, "y", text0_y_value = /*height_adj_nuts_text*/ ctx[9][/*d*/ ctx[28][1] - 1]);
			attr(text0, "opacity", text0_opacity_value = /*isSmall*/ ctx[2] ? 1 : 0);
			attr(text1, "class", "nuts2_names_short svelte-10b1f5r");
			attr(text1, "dy", text1_dy_value = "" + (/*i*/ ctx[30] + "em"));
			attr(text1, "fill", color_nuts2_names);
			attr(text1, "y", text1_y_value = /*height_adj_nuts_text*/ ctx[9][/*d*/ ctx[28][1] - 1]);
			attr(text1, "opacity", text1_opacity_value = /*isSmall*/ ctx[2] ? 1 : 0);
		},
		m(target, anchor) {
			insert(target, text0, anchor);
			append(text0, t0);
			insert(target, text1, anchor);
			append(text1, t1);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*isSmall*/ 4 && text0_opacity_value !== (text0_opacity_value = /*isSmall*/ ctx[2] ? 1 : 0)) {
				attr(text0, "opacity", text0_opacity_value);
			}

			if (dirty[0] & /*isSmall*/ 4 && text1_opacity_value !== (text1_opacity_value = /*isSmall*/ ctx[2] ? 1 : 0)) {
				attr(text1, "opacity", text1_opacity_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text0);
			if (detaching) detach(text1);
		}
	};
}

// (216:4) {#each data.locations as location}
function create_each_block(ctx) {
	let g;
	let each0_anchor;
	let each1_anchor;
	let g_transform_value;
	let rect;
	let rect_x_value;
	let rect_y_value;
	let rect_width_value;
	let rect_height_value;
	let mounted;
	let dispose;
	let each_value_3 = /*location*/ ctx[25].skills;
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks_2[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	let each_value_2 = /*location*/ ctx[25].names_long;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_1[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = /*location*/ ctx[25].names_short;
	let each_blocks = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	function mouseover_handler(...args) {
		return /*mouseover_handler*/ ctx[14](/*location*/ ctx[25], ...args);
	}

	function mousemove_handler(...args) {
		return /*mousemove_handler*/ ctx[15](/*location*/ ctx[25], ...args);
	}

	return {
		c() {
			g = svg_element("g");

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			each0_anchor = empty();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each1_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			rect = svg_element("rect");
			attr(g, "class", "nuts2_group");

			attr(g, "transform", g_transform_value = "translate(\n\t\t\t\t\t\t\t" + /*$_xScale*/ ctx[7](/*isSmall*/ ctx[2]
			? 100 * (/*location*/ ctx[25].x_pos_small + 0.5) / /*noXpos*/ ctx[5]
			: 100 * (/*location*/ ctx[25].x_pos + 0.5) / /*noXpos*/ ctx[5]) + ",\n\t\t\t\t\t\t\t" + yScale(/*isSmall*/ ctx[2]
			? 100 * (/*location*/ ctx[25].y_pos_small + 0.5) / /*noYpos*/ ctx[6]
			: 100 * (/*location*/ ctx[25].y_pos + 0.5) / /*noYpos*/ ctx[6]) + "\n\t\t\t\t\t\t)");

			attr(rect, "class", "mouseover");

			attr(rect, "x", rect_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[7](100 * (/*location*/ ctx[25].x_pos_small + 0.5 * (1 - prop_space_x)) / /*noXpos*/ ctx[5])
			: /*$_xScale*/ ctx[7](100 * (/*location*/ ctx[25].x_pos + 0.5 * (1 - prop_space_x)) / /*noXpos*/ ctx[5]));

			attr(rect, "y", rect_y_value = /*isSmall*/ ctx[2]
			? yScale(100 * (/*location*/ ctx[25].y_pos_small + 0.5 * (1 + prop_space_y)) / /*noYpos*/ ctx[6])
			: yScale(100 * (/*location*/ ctx[25].y_pos + 0.5 * (1 + prop_space_y)) / /*noYpos*/ ctx[6]));

			attr(rect, "width", rect_width_value = /*$_xScale*/ ctx[7](prop_space_x * 100 / /*noXpos*/ ctx[5]));
			attr(rect, "height", rect_height_value = yScale(100 - prop_space_y * 100 / /*noYpos*/ ctx[6]));
			attr(rect, "fill", "transparent");
		},
		m(target, anchor) {
			insert(target, g, anchor);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(g, null);
			}

			append(g, each0_anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, each1_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}

			insert(target, rect, anchor);

			if (!mounted) {
				dispose = [
					listen(rect, "mouseover", function () {
						if (is_function(!/*isVerySmall*/ ctx[4] ? mouseover_handler : null)) (!/*isVerySmall*/ ctx[4] ? mouseover_handler : null).apply(this, arguments);
					}),
					listen(rect, "mousemove", function () {
						if (is_function(!/*isVerySmall*/ ctx[4] ? mousemove_handler : null)) (!/*isVerySmall*/ ctx[4] ? mousemove_handler : null).apply(this, arguments);
					}),
					listen(rect, "mouseout", /*onMouseout*/ ctx[11])
				];

				mounted = true;
			}
		},
		p(new_ctx, dirty) {
			ctx = new_ctx;

			if (dirty[0] & /*noYpos, $_xScale, noXpos*/ 224) {
				each_value_3 = /*location*/ ctx[25].skills;
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_3(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(g, each0_anchor);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_3.length;
			}

			if (dirty[0] & /*height_adj_nuts_text, isSmall*/ 516) {
				each_value_2 = /*location*/ ctx[25].names_long;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_2(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, each1_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_2.length;
			}

			if (dirty[0] & /*height_adj_nuts_text, isSmall*/ 516) {
				each_value_1 = /*location*/ ctx[25].names_short;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block_1(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value_1.length;
			}

			if (dirty[0] & /*$_xScale, isSmall, noXpos, noYpos*/ 228 && g_transform_value !== (g_transform_value = "translate(\n\t\t\t\t\t\t\t" + /*$_xScale*/ ctx[7](/*isSmall*/ ctx[2]
			? 100 * (/*location*/ ctx[25].x_pos_small + 0.5) / /*noXpos*/ ctx[5]
			: 100 * (/*location*/ ctx[25].x_pos + 0.5) / /*noXpos*/ ctx[5]) + ",\n\t\t\t\t\t\t\t" + yScale(/*isSmall*/ ctx[2]
			? 100 * (/*location*/ ctx[25].y_pos_small + 0.5) / /*noYpos*/ ctx[6]
			: 100 * (/*location*/ ctx[25].y_pos + 0.5) / /*noYpos*/ ctx[6]) + "\n\t\t\t\t\t\t)")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty[0] & /*isSmall, $_xScale, noXpos*/ 164 && rect_x_value !== (rect_x_value = /*isSmall*/ ctx[2]
			? /*$_xScale*/ ctx[7](100 * (/*location*/ ctx[25].x_pos_small + 0.5 * (1 - prop_space_x)) / /*noXpos*/ ctx[5])
			: /*$_xScale*/ ctx[7](100 * (/*location*/ ctx[25].x_pos + 0.5 * (1 - prop_space_x)) / /*noXpos*/ ctx[5]))) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty[0] & /*isSmall, noYpos*/ 68 && rect_y_value !== (rect_y_value = /*isSmall*/ ctx[2]
			? yScale(100 * (/*location*/ ctx[25].y_pos_small + 0.5 * (1 + prop_space_y)) / /*noYpos*/ ctx[6])
			: yScale(100 * (/*location*/ ctx[25].y_pos + 0.5 * (1 + prop_space_y)) / /*noYpos*/ ctx[6]))) {
				attr(rect, "y", rect_y_value);
			}

			if (dirty[0] & /*$_xScale, noXpos*/ 160 && rect_width_value !== (rect_width_value = /*$_xScale*/ ctx[7](prop_space_x * 100 / /*noXpos*/ ctx[5]))) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty[0] & /*noYpos*/ 64 && rect_height_value !== (rect_height_value = yScale(100 - prop_space_y * 100 / /*noYpos*/ ctx[6]))) {
				attr(rect, "height", rect_height_value);
			}
		},
		d(detaching) {
			if (detaching) detach(g);
			destroy_each(each_blocks_2, detaching);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
			if (detaching) detach(rect);
			mounted = false;
			run_all(dispose);
		}
	};
}

function create_fragment(ctx) {
	let div0;
	let p0;
	let span0;
	let t0_value = /*$_tooltip*/ ctx[8].name + "";
	let t0;
	let t1;
	let p1;
	let t3;
	let p2;
	let span2;
	let t4;
	let t5;
	let span3;
	let t6_value = /*$_tooltip*/ ctx[8].data_1 + "";
	let t6;
	let t7;
	let p3;
	let span4;
	let t8;
	let t9;
	let span5;
	let t10_value = /*$_tooltip*/ ctx[8].data_2 + "";
	let t10;
	let t11;
	let p4;
	let span6;
	let t12;
	let t13;
	let span7;
	let t14_value = /*$_tooltip*/ ctx[8].data_3 + "";
	let t14;
	let t15;
	let p5;
	let span8;
	let t16;
	let t17;
	let span9;
	let t18_value = /*$_tooltip*/ ctx[8].data_4 + "";
	let t18;
	let t19;
	let p6;
	let span10;
	let t20;
	let t21;
	let span11;
	let t22_value = /*$_tooltip*/ ctx[8].data_5 + "";
	let t22;
	let t23;
	let p7;
	let span12;
	let t24;
	let t25;
	let span13;
	let t26_value = /*$_tooltip*/ ctx[8].data_6 + "";
	let t26;
	let t27;
	let p8;
	let span14;
	let t28;
	let t29;
	let span15;
	let t30_value = /*$_tooltip*/ ctx[8].data_7 + "";
	let t30;
	let t31;
	let p9;
	let span16;
	let t32;
	let t33;
	let span17;
	let t34_value = /*$_tooltip*/ ctx[8].data_8 + "";
	let t34;
	let t35;
	let div1;
	let if_block = /*width*/ ctx[0] && /*height*/ ctx[3] && create_if_block(ctx);

	return {
		c() {
			div0 = element("div");
			p0 = element("p");
			span0 = element("span");
			t0 = text(t0_value);
			t1 = space();
			p1 = element("p");
			p1.innerHTML = `<span class="line2_text svelte-10b1f5r">Mix of all skills mentioned:</span>`;
			t3 = space();
			p2 = element("p");
			span2 = element("span");
			t4 = text("    ");
			t5 = space();
			span3 = element("span");
			t6 = text(t6_value);
			t7 = space();
			p3 = element("p");
			span4 = element("span");
			t8 = text("    ");
			t9 = space();
			span5 = element("span");
			t10 = text(t10_value);
			t11 = space();
			p4 = element("p");
			span6 = element("span");
			t12 = text("    ");
			t13 = space();
			span7 = element("span");
			t14 = text(t14_value);
			t15 = space();
			p5 = element("p");
			span8 = element("span");
			t16 = text("    ");
			t17 = space();
			span9 = element("span");
			t18 = text(t18_value);
			t19 = space();
			p6 = element("p");
			span10 = element("span");
			t20 = text("    ");
			t21 = space();
			span11 = element("span");
			t22 = text(t22_value);
			t23 = space();
			p7 = element("p");
			span12 = element("span");
			t24 = text("    ");
			t25 = space();
			span13 = element("span");
			t26 = text(t26_value);
			t27 = space();
			p8 = element("p");
			span14 = element("span");
			t28 = text("    ");
			t29 = space();
			span15 = element("span");
			t30 = text(t30_value);
			t31 = space();
			p9 = element("p");
			span16 = element("span");
			t32 = text("    ");
			t33 = space();
			span17 = element("span");
			t34 = text(t34_value);
			t35 = space();
			div1 = element("div");
			if (if_block) if_block.c();
			attr(span0, "class", "line1_text svelte-10b1f5r");
			attr(p0, "class", "line1_bground svelte-10b1f5r");
			attr(p1, "class", "line2_bground svelte-10b1f5r");
			attr(span2, "class", "line3_text");
			set_style(span2, "background-color", /*$_tooltip*/ ctx[8].background_1);
			set_style(span2, "border-radius", "4px");
			set_style(span2, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_1);
			attr(span3, "class", "line3_text");
			attr(p2, "class", "line3_bground svelte-10b1f5r");
			attr(span4, "class", "line4_text");
			set_style(span4, "background-color", /*$_tooltip*/ ctx[8].background_2);
			set_style(span4, "border-radius", "4px");
			set_style(span4, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_2);
			attr(span5, "class", "line4_text");
			attr(p3, "class", "line4_bground svelte-10b1f5r");
			attr(span6, "class", "line5_text");
			set_style(span6, "background-color", /*$_tooltip*/ ctx[8].background_3);
			set_style(span6, "border-radius", "4px");
			set_style(span6, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_3);
			attr(span7, "class", "line5_text");
			attr(p4, "class", "line5_bground svelte-10b1f5r");
			attr(span8, "class", "line6_text");
			set_style(span8, "background-color", /*$_tooltip*/ ctx[8].background_4);
			set_style(span8, "border-radius", "4px");
			set_style(span8, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_4);
			attr(span9, "class", "line6_text");
			attr(p5, "class", "line6_bground svelte-10b1f5r");
			attr(span10, "class", "line7_text");
			set_style(span10, "background-color", /*$_tooltip*/ ctx[8].background_5);
			set_style(span10, "border-radius", "4px");
			set_style(span10, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_5);
			attr(span11, "class", "line7_text");
			attr(p6, "class", "line7_bground svelte-10b1f5r");
			attr(span12, "class", "line8_text");
			set_style(span12, "background-color", /*$_tooltip*/ ctx[8].background_6);
			set_style(span12, "border-radius", "4px");
			set_style(span12, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_6);
			attr(span13, "class", "line8_text");
			attr(p7, "class", "line8_bground svelte-10b1f5r");
			attr(span14, "class", "line9_text");
			set_style(span14, "background-color", /*$_tooltip*/ ctx[8].background_7);
			set_style(span14, "border-radius", "4px");
			set_style(span14, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_7);
			attr(span15, "class", "line9_text");
			attr(p8, "class", "line9_bground svelte-10b1f5r");
			attr(span16, "class", "line10_text");
			set_style(span16, "background-color", /*$_tooltip*/ ctx[8].background_8);
			set_style(span16, "border-radius", "4px");
			set_style(span16, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_8);
			attr(span17, "class", "line10_text");
			attr(p9, "class", "line10_bground svelte-10b1f5r");
			attr(div0, "class", "tooltip svelte-10b1f5r");
			set_style(div0, "left", /*$_tooltip*/ ctx[8].left_margin);
			set_style(div0, "top", /*$_tooltip*/ ctx[8].top_margin);
			set_style(div0, "border", "3px solid " + /*$_tooltip*/ ctx[8].background_color);
			toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[8].isVisible);
			attr(div1, "class", "div_background svelte-10b1f5r");
		},
		m(target, anchor) {
			insert(target, div0, anchor);
			append(div0, p0);
			append(p0, span0);
			append(span0, t0);
			append(div0, t1);
			append(div0, p1);
			append(div0, t3);
			append(div0, p2);
			append(p2, span2);
			append(span2, t4);
			append(p2, t5);
			append(p2, span3);
			append(span3, t6);
			append(div0, t7);
			append(div0, p3);
			append(p3, span4);
			append(span4, t8);
			append(p3, t9);
			append(p3, span5);
			append(span5, t10);
			append(div0, t11);
			append(div0, p4);
			append(p4, span6);
			append(span6, t12);
			append(p4, t13);
			append(p4, span7);
			append(span7, t14);
			append(div0, t15);
			append(div0, p5);
			append(p5, span8);
			append(span8, t16);
			append(p5, t17);
			append(p5, span9);
			append(span9, t18);
			append(div0, t19);
			append(div0, p6);
			append(p6, span10);
			append(span10, t20);
			append(p6, t21);
			append(p6, span11);
			append(span11, t22);
			append(div0, t23);
			append(div0, p7);
			append(p7, span12);
			append(span12, t24);
			append(p7, t25);
			append(p7, span13);
			append(span13, t26);
			append(div0, t27);
			append(div0, p8);
			append(p8, span14);
			append(span14, t28);
			append(p8, t29);
			append(p8, span15);
			append(span15, t30);
			append(div0, t31);
			append(div0, p9);
			append(p9, span16);
			append(span16, t32);
			append(p9, t33);
			append(p9, span17);
			append(span17, t34);
			insert(target, t35, anchor);
			insert(target, div1, anchor);
			if (if_block) if_block.m(div1, null);
		},
		p(ctx, dirty) {
			if (dirty[0] & /*$_tooltip*/ 256 && t0_value !== (t0_value = /*$_tooltip*/ ctx[8].name + "")) set_data(t0, t0_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span2, "background-color", /*$_tooltip*/ ctx[8].background_1);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span2, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_1);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t6_value !== (t6_value = /*$_tooltip*/ ctx[8].data_1 + "")) set_data(t6, t6_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span4, "background-color", /*$_tooltip*/ ctx[8].background_2);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span4, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_2);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t10_value !== (t10_value = /*$_tooltip*/ ctx[8].data_2 + "")) set_data(t10, t10_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span6, "background-color", /*$_tooltip*/ ctx[8].background_3);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span6, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_3);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t14_value !== (t14_value = /*$_tooltip*/ ctx[8].data_3 + "")) set_data(t14, t14_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span8, "background-color", /*$_tooltip*/ ctx[8].background_4);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span8, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_4);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t18_value !== (t18_value = /*$_tooltip*/ ctx[8].data_4 + "")) set_data(t18, t18_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span10, "background-color", /*$_tooltip*/ ctx[8].background_5);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span10, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_5);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t22_value !== (t22_value = /*$_tooltip*/ ctx[8].data_5 + "")) set_data(t22, t22_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span12, "background-color", /*$_tooltip*/ ctx[8].background_6);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span12, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_6);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t26_value !== (t26_value = /*$_tooltip*/ ctx[8].data_6 + "")) set_data(t26, t26_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span14, "background-color", /*$_tooltip*/ ctx[8].background_7);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span14, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_7);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t30_value !== (t30_value = /*$_tooltip*/ ctx[8].data_7 + "")) set_data(t30, t30_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span16, "background-color", /*$_tooltip*/ ctx[8].background_8);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(span16, "border", "2px solid " + /*$_tooltip*/ ctx[8].background_8);
			}

			if (dirty[0] & /*$_tooltip*/ 256 && t34_value !== (t34_value = /*$_tooltip*/ ctx[8].data_8 + "")) set_data(t34, t34_value);

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(div0, "left", /*$_tooltip*/ ctx[8].left_margin);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(div0, "top", /*$_tooltip*/ ctx[8].top_margin);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				set_style(div0, "border", "3px solid " + /*$_tooltip*/ ctx[8].background_color);
			}

			if (dirty[0] & /*$_tooltip*/ 256) {
				toggle_class(div0, "hidden", !/*$_tooltip*/ ctx[8].isVisible);
			}

			if (/*width*/ ctx[0] && /*height*/ ctx[3]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div1, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div0);
			if (detaching) detach(t35);
			if (detaching) detach(div1);
			if (if_block) if_block.d();
		}
	};
}

const small_width = 850;

// For really small screens
// (stop showing mouseovers)
const very_small_width = 450;

// Proportion of each area (in each direction) that will be taken up by
// stacked bar chart (gives white space)
const prop_space_x = 0.95;

const prop_space_y = 0.9;

// Stroke width for text
const strokeWidth = "5px";

function instance($$self, $$props, $$invalidate) {
	let width;
	let height;
	let isSmall;
	let isVerySmall;
	let noXpos;
	let noYpos;
	let format_text;
	let $_width;
	let $_margin;
	let $_xScale;
	let $_tooltip;
	component_subscribe($$self, _width, $$value => $$invalidate(13, $_width = $$value));
	component_subscribe($$self, _margin, $$value => $$invalidate(1, $_margin = $$value));
	component_subscribe($$self, _xScale, $$value => $$invalidate(7, $_xScale = $$value));
	const height_adj_nuts_text = ["0.5em", "0em", "-0.5em", "-1em"];

	/* vars */
	// Number of x and y positions in the map
	let all_x_pos = data.locations.map(d => d.x_pos);

	let no_x_pos = Math.max.apply(Math, all_x_pos) + 1;
	let all_x_pos_small = data.locations.map(d => d.x_pos_small);
	let no_x_pos_small = all_x_pos_small.filter((item, i, ar) => ar.indexOf(item) === i).length;
	let all_y_pos = data.locations.map(d => d.y_pos);
	let no_y_pos = Math.max.apply(Math, all_y_pos) + 1;
	let all_y_pos_small = data.locations.map(d => d.y_pos_small);
	let no_y_pos_small = all_y_pos_small.filter((item, i, ar) => ar.indexOf(item) === i).length;
	const _tooltip = writable({ isVisible: false });
	component_subscribe($$self, _tooltip, value => $$invalidate(8, $_tooltip = value));

	const onMouseout = () => {
		_tooltip.set({ isVisible: false });
	};

	const showTooltip = (d, event) => {
		const left = isSmall
		? Math.min($_xScale(85), pointer(event)[0])
		: Math.min($_xScale(95), pointer(event)[0]);

		const top = event.pageY - 380;

		_tooltip.set({
			isVisible: true,
			left_margin: left + "px",
			top_margin: top + "px",
			background_color: color_tooltip_bground,
			data_1: format_text(d.skills[0].broad_skill_group, d.skills[0].percent),
			background_1: color_skills(d.skills[0].broad_skill_group),
			data_2: format_text(d.skills[1].broad_skill_group, d.skills[1].percent),
			background_2: color_skills(d.skills[1].broad_skill_group),
			data_3: format_text(d.skills[2].broad_skill_group, d.skills[2].percent),
			background_3: color_skills(d.skills[2].broad_skill_group),
			data_4: format_text(d.skills[3].broad_skill_group, d.skills[3].percent),
			background_4: color_skills(d.skills[3].broad_skill_group),
			data_5: format_text(d.skills[4].broad_skill_group, d.skills[4].percent),
			background_5: color_skills(d.skills[4].broad_skill_group),
			data_6: format_text(d.skills[5].broad_skill_group, d.skills[5].percent),
			background_6: color_skills(d.skills[5].broad_skill_group),
			data_7: format_text(d.skills[6].broad_skill_group, d.skills[6].percent),
			background_7: color_skills(d.skills[6].broad_skill_group),
			data_8: format_text(d.skills[7].broad_skill_group, d.skills[7].percent),
			background_8: color_skills(d.skills[7].broad_skill_group),
			name: d.nuts218nm
		});
	};

	const mouseover_handler = (location, isevent) => showTooltip(location, event);
	const mousemove_handler = (location, isevent) => showTooltip(location, event);

	$$self.$$.update = () => {
		if ($$self.$$.dirty[0] & /*$_width, $_margin*/ 8194) {
			/* reactive vars */
			$: $$invalidate(0, width = $_width + $_margin.left + $_margin.right);
		}

		if ($$self.$$.dirty[0] & /*$_margin*/ 2) {
			$: $$invalidate(3, height = chartsHeight + $_margin.top + $_margin.bottom);
		}

		if ($$self.$$.dirty[0] & /*width*/ 1) {
			$: $$invalidate(2, isSmall = width < small_width);
		}

		if ($$self.$$.dirty[0] & /*width*/ 1) {
			$: $$invalidate(4, isVerySmall = width < very_small_width);
		}

		if ($$self.$$.dirty[0] & /*isSmall*/ 4) {
			$: $$invalidate(5, noXpos = isSmall ? no_x_pos_small : no_x_pos);
		}

		if ($$self.$$.dirty[0] & /*isSmall*/ 4) {
			$: $$invalidate(6, noYpos = isSmall ? no_y_pos_small : no_y_pos);
		}
	};

	$: format_text = (broad_skill_group, percent) => {
		// If demand for skill is >=1%
		if (percent >= 1) {
			// Round to nearest integer
			return broad_skill_group + ": " + Math.round(percent) + "%";
		} else {
			return broad_skill_group + ": <1%";
		}
	};

	return [
		width,
		$_margin,
		isSmall,
		height,
		isVerySmall,
		noXpos,
		noYpos,
		$_xScale,
		$_tooltip,
		height_adj_nuts_text,
		_tooltip,
		onMouseout,
		showTooltip,
		$_width,
		mouseover_handler,
		mousemove_handler
	];
}

class ChartLocations extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {}, [-1, -1]);
	}
}

export default ChartLocations;