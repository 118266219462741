/* components/Margin.svelte generated by Svelte v3.38.2 */
import {
	SvelteComponent,
	append,
	attr,
	component_subscribe,
	destroy_each,
	detach,
	element,
	empty,
	init,
	insert,
	noop,
	safe_not_equal,
	svg_element,
	text,
	xlink_attr
} from "svelte/internal";

import { scaleLinear } from "d3-scale";
import data from "../../data/data.json";

import {
	color_margin_title,
	color_margin_skills,
	color_skills
} from "../shared/colours";

import { _marginM, _widthM, _heightM } from "../stores";

function get_each_context(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

function get_each_context_1(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

function get_each_context_2(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

function get_each_context_3(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

function get_each_context_4(ctx, list, i) {
	const child_ctx = ctx.slice();
	child_ctx[11] = list[i];
	child_ctx[13] = i;
	return child_ctx;
}

// (64:1) {#if width && height}
function create_if_block(ctx) {
	let svg;
	let g;
	let each0_anchor;
	let each1_anchor;
	let each2_anchor;
	let each3_anchor;
	let g_transform_value;
	let each_value_4 = data.skills;
	let each_blocks_4 = [];

	for (let i = 0; i < each_value_4.length; i += 1) {
		each_blocks_4[i] = create_each_block_4(get_each_context_4(ctx, each_value_4, i));
	}

	let each_value_3 = data.skills;
	let each_blocks_3 = [];

	for (let i = 0; i < each_value_3.length; i += 1) {
		each_blocks_3[i] = create_each_block_3(get_each_context_3(ctx, each_value_3, i));
	}

	let each_value_2 = data.skills;
	let each_blocks_2 = [];

	for (let i = 0; i < each_value_2.length; i += 1) {
		each_blocks_2[i] = create_each_block_2(get_each_context_2(ctx, each_value_2, i));
	}

	let each_value_1 = data.skills;
	let each_blocks_1 = [];

	for (let i = 0; i < each_value_1.length; i += 1) {
		each_blocks_1[i] = create_each_block_1(get_each_context_1(ctx, each_value_1, i));
	}

	let each_value = /*titleLines*/ ctx[8];
	let each_blocks = [];

	for (let i = 0; i < each_value.length; i += 1) {
		each_blocks[i] = create_each_block(get_each_context(ctx, each_value, i));
	}

	return {
		c() {
			svg = svg_element("svg");
			g = svg_element("g");

			for (let i = 0; i < each_blocks_4.length; i += 1) {
				each_blocks_4[i].c();
			}

			each0_anchor = empty();

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				each_blocks_3[i].c();
			}

			each1_anchor = empty();

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].c();
			}

			each2_anchor = empty();

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].c();
			}

			each3_anchor = empty();

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].c();
			}

			attr(g, "transform", g_transform_value = "translate(" + /*$_marginM*/ ctx[2].left + "," + /*$_marginM*/ ctx[2].top + ")");
			attr(svg, "width", /*width*/ ctx[4]);
			attr(svg, "height", /*height*/ ctx[5]);
		},
		m(target, anchor) {
			insert(target, svg, anchor);
			append(svg, g);

			for (let i = 0; i < each_blocks_4.length; i += 1) {
				each_blocks_4[i].m(g, null);
			}

			append(g, each0_anchor);

			for (let i = 0; i < each_blocks_3.length; i += 1) {
				each_blocks_3[i].m(g, null);
			}

			append(g, each1_anchor);

			for (let i = 0; i < each_blocks_2.length; i += 1) {
				each_blocks_2[i].m(g, null);
			}

			append(g, each2_anchor);

			for (let i = 0; i < each_blocks_1.length; i += 1) {
				each_blocks_1[i].m(g, null);
			}

			append(g, each3_anchor);

			for (let i = 0; i < each_blocks.length; i += 1) {
				each_blocks[i].m(g, null);
			}
		},
		p(ctx, dirty) {
			if (dirty & /*_xScale_m, _yScale_m, yMax, no_skills, color_skills, data*/ 131) {
				each_value_4 = data.skills;
				let i;

				for (i = 0; i < each_value_4.length; i += 1) {
					const child_ctx = get_each_context_4(ctx, each_value_4, i);

					if (each_blocks_4[i]) {
						each_blocks_4[i].p(child_ctx, dirty);
					} else {
						each_blocks_4[i] = create_each_block_4(child_ctx);
						each_blocks_4[i].c();
						each_blocks_4[i].m(g, each0_anchor);
					}
				}

				for (; i < each_blocks_4.length; i += 1) {
					each_blocks_4[i].d(1);
				}

				each_blocks_4.length = each_value_4.length;
			}

			if (dirty & /*cx, _yScale_m, yMax, no_skills, color_skills, data, Math*/ 138) {
				each_value_3 = data.skills;
				let i;

				for (i = 0; i < each_value_3.length; i += 1) {
					const child_ctx = get_each_context_3(ctx, each_value_3, i);

					if (each_blocks_3[i]) {
						each_blocks_3[i].p(child_ctx, dirty);
					} else {
						each_blocks_3[i] = create_each_block_3(child_ctx);
						each_blocks_3[i].c();
						each_blocks_3[i].m(g, each1_anchor);
					}
				}

				for (; i < each_blocks_3.length; i += 1) {
					each_blocks_3[i].d(1);
				}

				each_blocks_3.length = each_value_3.length;
			}

			if (dirty & /*makeArc*/ 64) {
				each_value_2 = data.skills;
				let i;

				for (i = 0; i < each_value_2.length; i += 1) {
					const child_ctx = get_each_context_2(ctx, each_value_2, i);

					if (each_blocks_2[i]) {
						each_blocks_2[i].p(child_ctx, dirty);
					} else {
						each_blocks_2[i] = create_each_block_2(child_ctx);
						each_blocks_2[i].c();
						each_blocks_2[i].m(g, each2_anchor);
					}
				}

				for (; i < each_blocks_2.length; i += 1) {
					each_blocks_2[i].d(1);
				}

				each_blocks_2.length = each_value_2.length;
			}

			if (dirty & /*data*/ 0) {
				each_value_1 = data.skills;
				let i;

				for (i = 0; i < each_value_1.length; i += 1) {
					const child_ctx = get_each_context_1(ctx, each_value_1, i);

					if (each_blocks_1[i]) {
						each_blocks_1[i].p(child_ctx, dirty);
					} else {
						each_blocks_1[i] = create_each_block_1(child_ctx);
						each_blocks_1[i].c();
						each_blocks_1[i].m(g, each3_anchor);
					}
				}

				for (; i < each_blocks_1.length; i += 1) {
					each_blocks_1[i].d(1);
				}

				each_blocks_1.length = each_value_1.length;
			}

			if (dirty & /*color_margin_title, cx, _yScale_m, yMax, titleLines*/ 266) {
				each_value = /*titleLines*/ ctx[8];
				let i;

				for (i = 0; i < each_value.length; i += 1) {
					const child_ctx = get_each_context(ctx, each_value, i);

					if (each_blocks[i]) {
						each_blocks[i].p(child_ctx, dirty);
					} else {
						each_blocks[i] = create_each_block(child_ctx);
						each_blocks[i].c();
						each_blocks[i].m(g, null);
					}
				}

				for (; i < each_blocks.length; i += 1) {
					each_blocks[i].d(1);
				}

				each_blocks.length = each_value.length;
			}

			if (dirty & /*$_marginM*/ 4 && g_transform_value !== (g_transform_value = "translate(" + /*$_marginM*/ ctx[2].left + "," + /*$_marginM*/ ctx[2].top + ")")) {
				attr(g, "transform", g_transform_value);
			}

			if (dirty & /*width*/ 16) {
				attr(svg, "width", /*width*/ ctx[4]);
			}

			if (dirty & /*height*/ 32) {
				attr(svg, "height", /*height*/ ctx[5]);
			}
		},
		d(detaching) {
			if (detaching) detach(svg);
			destroy_each(each_blocks_4, detaching);
			destroy_each(each_blocks_3, detaching);
			destroy_each(each_blocks_2, detaching);
			destroy_each(each_blocks_1, detaching);
			destroy_each(each_blocks, detaching);
		}
	};
}

// (69:4) {#each data.skills as d, i}
function create_each_block_4(ctx) {
	let rect;
	let rect_x_value;
	let rect_y_value;
	let rect_stroke_value;
	let rect_width_value;
	let rect_height_value;

	return {
		c() {
			rect = svg_element("rect");
			attr(rect, "class", "skills_rects");
			attr(rect, "x", rect_x_value = /*_xScale_m*/ ctx[0](5));
			attr(rect, "y", rect_y_value = /*_yScale_m*/ ctx[1](yMax - 1.7 - 100 / (/*no_skills*/ ctx[7] + 1) * (/*i*/ ctx[13] + 0.5)));
			attr(rect, "fill", "transparent");
			attr(rect, "stroke", rect_stroke_value = color_skills(/*d*/ ctx[11].broad_skill_group));
			attr(rect, "stroke-width", "4px");
			attr(rect, "width", rect_width_value = /*_xScale_m*/ ctx[0](90));
			attr(rect, "height", rect_height_value = /*_yScale_m*/ ctx[1](100 - 77 / (/*no_skills*/ ctx[7] + 1)));
			attr(rect, "rx", "6px");
			attr(rect, "ry", "6px");
		},
		m(target, anchor) {
			insert(target, rect, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*_xScale_m*/ 1 && rect_x_value !== (rect_x_value = /*_xScale_m*/ ctx[0](5))) {
				attr(rect, "x", rect_x_value);
			}

			if (dirty & /*_yScale_m*/ 2 && rect_y_value !== (rect_y_value = /*_yScale_m*/ ctx[1](yMax - 1.7 - 100 / (/*no_skills*/ ctx[7] + 1) * (/*i*/ ctx[13] + 0.5)))) {
				attr(rect, "y", rect_y_value);
			}

			if (dirty & /*_xScale_m*/ 1 && rect_width_value !== (rect_width_value = /*_xScale_m*/ ctx[0](90))) {
				attr(rect, "width", rect_width_value);
			}

			if (dirty & /*_yScale_m*/ 2 && rect_height_value !== (rect_height_value = /*_yScale_m*/ ctx[1](100 - 77 / (/*no_skills*/ ctx[7] + 1)))) {
				attr(rect, "height", rect_height_value);
			}
		},
		d(detaching) {
			if (detaching) detach(rect);
		}
	};
}

// (85:4) {#each data.skills as d, i}
function create_each_block_3(ctx) {
	let circle;
	let circle_cy_value;
	let circle_fill_value;
	let circle_r_value;

	return {
		c() {
			circle = svg_element("circle");
			attr(circle, "cx", /*cx*/ ctx[3]);
			attr(circle, "class", "skills_circles");
			attr(circle, "cy", circle_cy_value = /*_yScale_m*/ ctx[1](yMax - 1.5 - 100 / (/*no_skills*/ ctx[7] + 1) * (/*i*/ ctx[13] + 1)));
			attr(circle, "fill", circle_fill_value = color_skills(/*d*/ ctx[11].broad_skill_group));
			attr(circle, "r", circle_r_value = Math.sqrt(/*d*/ ctx[11].percent * 200 / Math.PI));
		},
		m(target, anchor) {
			insert(target, circle, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*cx*/ 8) {
				attr(circle, "cx", /*cx*/ ctx[3]);
			}

			if (dirty & /*_yScale_m*/ 2 && circle_cy_value !== (circle_cy_value = /*_yScale_m*/ ctx[1](yMax - 1.5 - 100 / (/*no_skills*/ ctx[7] + 1) * (/*i*/ ctx[13] + 1)))) {
				attr(circle, "cy", circle_cy_value);
			}
		},
		d(detaching) {
			if (detaching) detach(circle);
		}
	};
}

// (96:4) {#each data.skills as d, i}
function create_each_block_2(ctx) {
	let path;
	let path_id_value;
	let path_d_value;

	return {
		c() {
			path = svg_element("path");
			attr(path, "class", "skills_arcs svelte-sklddr");
			attr(path, "id", path_id_value = "margin_arc" + /*i*/ ctx[13]);
			attr(path, "d", path_d_value = /*makeArc*/ ctx[6](/*i*/ ctx[13]));
		},
		m(target, anchor) {
			insert(target, path, anchor);
		},
		p(ctx, dirty) {
			if (dirty & /*makeArc*/ 64 && path_d_value !== (path_d_value = /*makeArc*/ ctx[6](/*i*/ ctx[13]))) {
				attr(path, "d", path_d_value);
			}
		},
		d(detaching) {
			if (detaching) detach(path);
		}
	};
}

// (105:4) {#each data.skills as d,i}
function create_each_block_1(ctx) {
	let g;
	let text_1;
	let textPath;
	let t_value = /*d*/ ctx[11].fine_skill_group + "";
	let t;
	let textPath_xlink_href_value;

	return {
		c() {
			g = svg_element("g");
			text_1 = svg_element("text");
			textPath = svg_element("textPath");
			t = text(t_value);
			attr(textPath, "class", "skills_text svelte-sklddr");
			attr(textPath, "fill", "#000000");
			attr(textPath, "startOffset", "50%");
			xlink_attr(textPath, "xlink:href", textPath_xlink_href_value = "#margin_arc" + /*i*/ ctx[13]);
			attr(g, "class", "arc_paths");
		},
		m(target, anchor) {
			insert(target, g, anchor);
			append(g, text_1);
			append(text_1, textPath);
			append(textPath, t);
		},
		p: noop,
		d(detaching) {
			if (detaching) detach(g);
		}
	};
}

// (123:4) {#each titleLines as d, i}
function create_each_block(ctx) {
	let text_1;
	let t_value = /*d*/ ctx[11] + "";
	let t;
	let text_1_dy_value;
	let text_1_y_value;

	return {
		c() {
			text_1 = svg_element("text");
			t = text(t_value);
			attr(text_1, "class", "title svelte-sklddr");
			attr(text_1, "dy", text_1_dy_value = 1.5 * /*i*/ ctx[13] + "em");
			attr(text_1, "fill", color_margin_title);
			attr(text_1, "x", /*cx*/ ctx[3]);
			attr(text_1, "y", text_1_y_value = /*_yScale_m*/ ctx[1](yMax - 2));
		},
		m(target, anchor) {
			insert(target, text_1, anchor);
			append(text_1, t);
		},
		p(ctx, dirty) {
			if (dirty & /*cx*/ 8) {
				attr(text_1, "x", /*cx*/ ctx[3]);
			}

			if (dirty & /*_yScale_m*/ 2 && text_1_y_value !== (text_1_y_value = /*_yScale_m*/ ctx[1](yMax - 2))) {
				attr(text_1, "y", text_1_y_value);
			}
		},
		d(detaching) {
			if (detaching) detach(text_1);
		}
	};
}

function create_fragment(ctx) {
	let div;
	let if_block = /*width*/ ctx[4] && /*height*/ ctx[5] && create_if_block(ctx);

	return {
		c() {
			div = element("div");
			if (if_block) if_block.c();
			attr(div, "class", "div_background svelte-sklddr");
		},
		m(target, anchor) {
			insert(target, div, anchor);
			if (if_block) if_block.m(div, null);
		},
		p(ctx, [dirty]) {
			if (/*width*/ ctx[4] && /*height*/ ctx[5]) {
				if (if_block) {
					if_block.p(ctx, dirty);
				} else {
					if_block = create_if_block(ctx);
					if_block.c();
					if_block.m(div, null);
				}
			} else if (if_block) {
				if_block.d(1);
				if_block = null;
			}
		},
		i: noop,
		o: noop,
		d(detaching) {
			if (detaching) detach(div);
			if (if_block) if_block.d();
		}
	};
}

const yMax = 100;

/* consts */
// Convert polar to cartesian co-ordainations (for positioning of skill labels)
function polarToCartesian(centerX, centerY, radius, angleInDegrees) {
	let angleInRadians = (angleInDegrees - 90) * Math.PI / 180;

	return {
		x: centerX + radius * Math.cos(angleInRadians),
		y: centerY + radius * Math.sin(angleInRadians)
	};
}

// Arc that describes skill labels
function describeArc(x, y, radius, startAngle, endAngle) {
	let start = polarToCartesian(x, y, radius, endAngle);
	let end = polarToCartesian(x, y, radius, startAngle);
	let d = ["M", start.x, start.y, "A", radius, radius, 0, 1, 1, end.x, end.y].join(" ");
	return d;
}

function instance($$self, $$props, $$invalidate) {
	let _xScale_m;
	let _yScale_m;
	let width;
	let height;
	let cx;
	let makeArc;
	let $_widthM;
	let $_heightM;
	let $_marginM;
	component_subscribe($$self, _widthM, $$value => $$invalidate(9, $_widthM = $$value));
	component_subscribe($$self, _heightM, $$value => $$invalidate(10, $_heightM = $$value));
	component_subscribe($$self, _marginM, $$value => $$invalidate(2, $_marginM = $$value));
	const no_skills = data.skills.length;
	const titleLines = ["Fine skill groups", "ordered by mentions", "(most often to least)"];

	$$self.$$.update = () => {
		if ($$self.$$.dirty & /*$_widthM*/ 512) {
			/* reactive vars */
			$: $$invalidate(0, _xScale_m = scaleLinear().domain([0, 100]).range([0, $_widthM]));
		}

		if ($$self.$$.dirty & /*$_heightM*/ 1024) {
			$: $$invalidate(1, _yScale_m = scaleLinear().domain([0, yMax]).range([$_heightM, 0]));
		}

		if ($$self.$$.dirty & /*$_widthM, $_marginM*/ 516) {
			$: $$invalidate(4, width = $_widthM + $_marginM.left + $_marginM.right);
		}

		if ($$self.$$.dirty & /*$_heightM, $_marginM*/ 1028) {
			$: $$invalidate(5, height = $_heightM + $_marginM.top + $_marginM.bottom);
		}

		if ($$self.$$.dirty & /*_xScale_m*/ 1) {
			$: $$invalidate(3, cx = _xScale_m(50));
		}

		if ($$self.$$.dirty & /*_yScale_m, cx*/ 10) {
			$: $$invalidate(6, makeArc = index => {
				let y = _yScale_m(yMax - 1.5 - 100 / (no_skills + 1) * (index + 1));
				let radius = 45;
				let startAngle = 160;
				let endAngle = -160;
				return describeArc(cx, y, radius, startAngle, endAngle);
			});
		}
	};

	return [
		_xScale_m,
		_yScale_m,
		$_marginM,
		cx,
		width,
		height,
		makeArc,
		no_skills,
		titleLines,
		$_widthM,
		$_heightM
	];
}

class Margin extends SvelteComponent {
	constructor(options) {
		super();
		init(this, options, instance, create_fragment, safe_not_equal, {});
	}
}

export default Margin;